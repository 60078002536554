import {Injectable} from "@angular/core";
import * as CryptoJS from 'crypto-js';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class Utility {
  constructor() {}

  encrypt(text: string) {
    let parsedBase64Key = CryptoJS.enc.Base64.parse(environment.base64);
    let encryptedData = CryptoJS.AES.encrypt(text, parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encryptedData.toString();
  } 

  decrypt(text: string) {
    let parsedBase64Key = CryptoJS.enc.Base64.parse(environment.base64);
    let decryptedData = CryptoJS.AES.decrypt(text, parsedBase64Key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    } );
    let decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return decryptedText;
  }
}