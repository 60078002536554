import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AdminWindowConfigureService {
  constructor(private http: HttpClient) {}

  postAddNewWindow(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/v1/windows/create`, data);
  }

  putUpdateWindow(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/windows/update/edit`,
      data
    );
  }

  getCycle(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/cycles`);
  }

  closeWindow(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/v1/windows/update/close`,
      data
    );
  }

  getLevels(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/levels`);
  }
  getCycleLevels(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/cycle/levels`);
  }
  getUsersForMMLevelPromotion(levelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/users/level/${levelId}`);
  }
}
