import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrMessageService } from 'src/app/core/services/toast-message.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { CAN_CHANGE_LANGUAGE, CAN_VIEW_NOTIFICATION, PCADMIN } from 'src/app/core/constants/string.constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LanguagesService } from 'src/app/core/services/languages.service';
import { DefaultLanguages, Languages, Timezone } from 'src/app/core/constants/languages.constants';
import { COLUMN_HEADER, MONTHS } from 'src/app/core/constants/month.constants';
import { FISCALMONTHS } from 'src/app/core/constants/fiscalMonth.constants';
import { AdminNotificationService } from 'src/app/core/services/admin-create-notification.service';
import { map } from 'rxjs/operators';
import { Subscription, timer } from 'rxjs';
import { MARKETING_MANAGER } from 'src/app/core/constants/tenant.constants';
import { Utility } from '../util/utility';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild('profilePopup', { static: false }) profileDetailsPopup: ElementRef;

  username = '';
  geography = [];
  geo = '';
  profile: any;
  defaultGeograhy;
  configs;
  defaultTenant;
  tenants: any;
  currentTenant: any;
  pcAdmin = PCADMIN;
  authorities: string[] = [];
  CAN_CHANGE_LANGUAGE =CAN_CHANGE_LANGUAGE
  CAN_VIEW_NOTIFICATION=CAN_VIEW_NOTIFICATION

  languagesList = [];
  fetchedNotificationContent=[];
  filterForm: FormGroup;
  timezone = Timezone;
  languages = Languages;
  defaultLanguage = Languages.LABEL_DEFAULT_LANG_ID;
  languageObject: any;
  unreadCount: any;
  timerSubscription: Subscription;
  interval:any;
  timeInterval: number=60*1000*1;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastMessageService: ToastrMessageService,
    private modalService: NgbModal,
    private tenantService: TenantService,
    private fb: FormBuilder,
    private languagesService: LanguagesService,
    private adminNotificationService:AdminNotificationService,
    private utility : Utility
  ) {
    this.currentTenant = this.tenantService.getTenant();

    this.authorities = localStorage.getItem('authorities').split(',');

  }
 

  ngOnInit() {
    this.tenants = JSON.parse(localStorage.getItem('tenants'));
    this.defaultTenant = localStorage.getItem('defaultTenant');
    this.profile = JSON.parse(localStorage.getItem('profile'));
     this.languageObject=JSON.parse(localStorage.getItem('language'));
    
    this.username = this.profile.name;
    this.geography = JSON.parse(localStorage.getItem('geographies'));
    this.geo = this.profile.geoCategory;
    this.defaultGeograhy = Number(localStorage.getItem('defaultGeography'));
    this.configs = JSON.parse(localStorage.getItem('config'));
    if (this.configs.faviconUrl) {
      const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
      favIcon.href = this.configs.faviconUrl;
    }

    this.filterForm = this.fb.group({
      language: [null]
    });
    this.getLanguages();
    this.setDefaultLanguageOfUser();
    this.getLabelsAsPerLanguage(this.getSelectedLanguage.value);
    this.getSelectedLanguage.valueChanges.subscribe(selectedres => {
      this.updateLanguagePreference(this.getSelectedLanguage.value);
      this.getLabelsAsPerLanguage(this.getSelectedLanguage.value);
      
    });
     this.getTimezone();
     this.fetchNotificationCount();
     this.clearIntervalFunction();
     this.setTimeIntervalFunction();
  }
  ngOnDestroy() {
    this.clearIntervalFunction();
    }
    clearIntervalFunction(){
      if (this.interval) {
        clearInterval(this.interval);
      }
    }
    setTimeIntervalFunction(){
      this.interval= setInterval(() => {
        this.fetchNotificationCount();
      },this.timeInterval);
    }
  getLanguages() {
    this.languagesService.getAllLanguages().subscribe(res => {
      this.languagesList = res ? res : [];
    });
  }
// language component 

  updateLanguagePreference(selectedLang) {
    let langPrefered = {
      languagePreferenceId: selectedLang
    }
    const langObject=this.languagesList.find(lang=>lang.languageId==langPrefered.languagePreferenceId);
    this.languagesService.updateLanguagePreferences(langPrefered).subscribe(res => {
      localStorage.setItem('language', JSON.stringify(langObject));
      this.reloadCurrentRoute();
    });
  }

  setDefaultLanguageOfUser() {
    if (this.languageObject && this.languageObject.languageId) {
      this.filterForm.controls.language.setValue(this.languageObject.languageId);
    } else {
      this.filterForm.controls.language.setValue(this.defaultLanguage);
    }
  }
  getLabelsAsPerLanguage(selectedLanguage) {
    this.languagesService.getLabelsFromLangId(selectedLanguage).subscribe(res => {
      this.updateLanguage(res);
    });
  }

  getTimezone() {
    if (this.profile.timezone && this.profile.timezone.symbol) {
      Timezone.TIMEZONE =  this.profile.timezone.symbol;
      Timezone.OFFSET =  this.profile.timezone.offset;
    }
    else
      Timezone.TIMEZONE = this.timezone.TIMEZONE;
      Timezone.OFFSET =  this.timezone.OFFSET;
  }

  get getSelectedLanguage() {
    return this.filterForm.controls.language;
  }

  changeRole() {
    localStorage.setItem('canViewGeo', 'true');
    this.router.navigate(['/geography']);
  }

  changeLevel() {
    localStorage.setItem('canViewLevel', 'true');
    this.router.navigate(['/level']);
  }

  changeProfitCenter() {
    localStorage.setItem("previousTenant", this.defaultTenant);
    localStorage.removeItem("defaultTenant");
    this.router.navigate(["/profit-center"]);
  }

  openProfilePopup() {
    this.modalService.open(this.profileDetailsPopup, { centered: true });
  }

  getLevel() {
    if (this.profile.level !== 'Profit Center Admin' && this.profile.level !== 'PC Admin') {
      return this.profile.level.match(/\b(\w)/g).join('').toUpperCase();
    } else {
      return PCADMIN;
    }
  }

  fetchCurrentGeo() {
    if (this.getLevel() === 'TM' || this.getLevel() === 'RM' || this.getLevel() === 'ZM' || this.getLevel() === 'AM' || this.getLevel() === 'SBU') {
      if (this.defaultGeograhy) {
        let geoSelected = ' - ';
        let geoCode = ' - ';
        this.geography.forEach(geo => {
          if (geo.geoId === this.defaultGeograhy) {
            geoSelected = geo.geoName;
            geoCode = geo.geoCode;
          }
        });
        return `${geoCode} - ${geoSelected}`;
      } else {
        return ' - '
      }
    } else {
      if (this.defaultTenant) {
        let selectedTenant = ' - ';
        let selectedTenantCode = ' - ';
        this.tenants.map(tenant => {
          if (tenant.code === this.defaultTenant) {
            selectedTenant = tenant.name;
            selectedTenantCode = tenant.code;
          }
        });
        return `${selectedTenantCode} - ${selectedTenant}`;
      } else {
        return ' - ';
      }
    }
  }

  getGeoTenant() {
    if (this.getLevel() === 'TM' || this.getLevel() === 'RM' || this.getLevel() === 'ZM' || this.getLevel() === 'AM' || this.getLevel() === 'SBU') {
      let geos = [];
      this.geography.forEach(geo => {
        geos.push({ code: geo.geoCode, name: geo.geoName });
      });
      return geos;
    } else {
      let tenants = [];
      this.tenants.map(tenant => {
        tenants.push({ code: tenant.code, name: tenant.name });
      });
      return tenants;
    }
  }

  logout() {
    if (environment.production) {
      this.authService.logout().subscribe(res => {
        //response
      }, err => {
        //error
        console.log(err);
      });
    } else {
      this.authService.logoutEmployee().subscribe(res => {
        //response
      }, err => {
        //error
        console.log(err);
      });
    }
  }

  updateLanguage(res) {
    if (res.LABEL_FILE_PROCESSING)
      Languages.LABEL_FILE_PROCESSING = res.LABEL_FILE_PROCESSING;
    else
      Languages.LABEL_FILE_PROCESSING = DefaultLanguages.LABEL_FILE_PROCESSING;
    if (res.LABEL_MASTER_FILE)
      Languages.LABEL_MASTER_FILE = res.LABEL_MASTER_FILE;
    else
      Languages.LABEL_MASTER_FILE = DefaultLanguages.LABEL_MASTER_FILE;
    if (res.LABEL_TRANSACTION_FILE)
      Languages.LABEL_TRANSACTION_FILE = res.LABEL_TRANSACTION_FILE;
    else
      Languages.LABEL_TRANSACTION_FILE = DefaultLanguages.LABEL_TRANSACTION_FILE;
    if (res.LABEL_CONFIGURE_WINDOW)
      Languages.LABEL_CONFIGURE_WINDOW = res.LABEL_CONFIGURE_WINDOW;
    else
      Languages.LABEL_CONFIGURE_WINDOW = DefaultLanguages.LABEL_CONFIGURE_WINDOW;
    if (res.LABEL_WINDOW_START_DATE)
      Languages.LABEL_WINDOW_START_DATE = res.LABEL_WINDOW_START_DATE;
    else
      Languages.LABEL_WINDOW_START_DATE = DefaultLanguages.LABEL_WINDOW_START_DATE;
    if (res.LABEL_WINDOW_END_DATE)
      Languages.LABEL_WINDOW_END_DATE = res.LABEL_WINDOW_END_DATE;
    else
      Languages.LABEL_WINDOW_END_DATE = DefaultLanguages.LABEL_WINDOW_END_DATE;
    if (res.LABEL_WINDOW_TIME)
      Languages.LABEL_WINDOW_TIME = res.LABEL_WINDOW_TIME;
    else
      Languages.LABEL_WINDOW_TIME = DefaultLanguages.LABEL_WINDOW_TIME;
    if (res.LABEL_CLOSE_NOW)
      Languages.LABEL_CLOSE_NOW = res.LABEL_CLOSE_NOW;
    else
      Languages.LABEL_CLOSE_NOW = DefaultLanguages.LABEL_CLOSE_NOW;

    if (res.LABEL_PUSH_FILE_TO_SAP)
      Languages.LABEL_PUSH_FILE_TO_SAP = res.LABEL_PUSH_FILE_TO_SAP;
    else
      Languages.LABEL_PUSH_FILE_TO_SAP = DefaultLanguages.LABEL_PUSH_FILE_TO_SAP;
    if (res.LABEL_FOREACASTING_CYCLE)
      Languages.LABEL_FOREACASTING_CYCLE = res.LABEL_FOREACASTING_CYCLE;
    else
      Languages.LABEL_FOREACASTING_CYCLE = DefaultLanguages.LABEL_FOREACASTING_CYCLE;



    if (res.LABEL_FORECASTING_SHEET)
      Languages.LABEL_FORECASTING_SHEET = res.LABEL_FORECASTING_SHEET;
    else
      Languages.LABEL_FORECASTING_SHEET = DefaultLanguages.LABEL_FORECASTING_SHEET;
    if (res.LABEL_ADOPTION_STATISTICS)
      Languages.LABEL_ADOPTION_STATISTICS = res.LABEL_ADOPTION_STATISTICS;
    else
      Languages.LABEL_ADOPTION_STATISTICS = DefaultLanguages.LABEL_ADOPTION_STATISTICS;
    if (res.LABEL_REPORTS)
      Languages.LABEL_REPORTS = res.LABEL_REPORTS;
    else
      Languages.LABEL_REPORTS = DefaultLanguages.LABEL_REPORTS;
    if (res.LABEL_CREATE_NOTIFICATION)
      Languages.LABEL_CREATE_NOTIFICATION = res.LABEL_CREATE_NOTIFICATION;
    else
      Languages.LABEL_CREATE_NOTIFICATION = DefaultLanguages.LABEL_CREATE_NOTIFICATION;
    if (res.LABEL_FINANCIAL_YEAR)
      Languages.LABEL_FINANCIAL_YEAR = res.LABEL_FINANCIAL_YEAR;
    else
      Languages.LABEL_FINANCIAL_YEAR = DefaultLanguages.LABEL_FINANCIAL_YEAR;
    if (res.LABEL_MATERIAL_GROUP)
      Languages.LABEL_MATERIAL_GROUP = res.LABEL_MATERIAL_GROUP;
    else
      Languages.LABEL_MATERIAL_GROUP = DefaultLanguages.LABEL_MATERIAL_GROUP;
    if (res.LABEL_PRODUCT_BRAND)
      Languages.LABEL_PRODUCT_BRAND = res.LABEL_PRODUCT_BRAND;
    else
      Languages.LABEL_PRODUCT_BRAND = DefaultLanguages.LABEL_PRODUCT_BRAND;
    if (res.LABEL_ERROR_CATEGORY)
      Languages.LABEL_ERROR_CATEGORY = res.LABEL_ERROR_CATEGORY;
    else
      Languages.LABEL_ERROR_CATEGORY = DefaultLanguages.LABEL_ERROR_CATEGORY;
    if (res.LABEL_SKU)
      Languages.LABEL_SKU = res.LABEL_SKU;
    else
      Languages.LABEL_SKU = DefaultLanguages.LABEL_SKU;
    if (res.LABEL_ZONE)
      Languages.LABEL_ZONE = res.LABEL_ZONE;
    else
      Languages.LABEL_ZONE = DefaultLanguages.LABEL_ZONE;
    if (res.LABEL_REGION)
      Languages.LABEL_REGION = res.LABEL_REGION;
    else
      Languages.LABEL_REGION = DefaultLanguages.LABEL_REGION;
    if (res.LABEL_TERRITORY)
      Languages.LABEL_TERRITORY = res.LABEL_TERRITORY;
    else
      Languages.LABEL_TERRITORY = DefaultLanguages.LABEL_TERRITORY;
    if (res.LABEL_CLOSE)
      Languages.LABEL_CLOSE = res.LABEL_CLOSE;
    else
      Languages.LABEL_CLOSE = DefaultLanguages.LABEL_CLOSE;
    if (res.LABEL_ALL_VALUES_IN_INR)
      Languages.LABEL_ALL_VALUES_IN_INR = res.LABEL_ALL_VALUES_IN_INR;
    else
      Languages.LABEL_ALL_VALUES_IN_INR = DefaultLanguages.LABEL_ALL_VALUES_IN_INR;
    if (res.LABEL_CRORES)
      Languages.LABEL_CRORES = res.LABEL_CRORES;
    else
      Languages.LABEL_CRORES = DefaultLanguages.LABEL_CRORES;
    if (res.LABEL_LAKHS)
      Languages.LABEL_LAKHS = res.LABEL_LAKHS;
    else
      Languages.LABEL_LAKHS = DefaultLanguages.LABEL_LAKHS;
    if (res.LABEL_ABSOLUTE)
      Languages.LABEL_ABSOLUTE = res.LABEL_ABSOLUTE;
    else
      Languages.LABEL_ABSOLUTE = DefaultLanguages.LABEL_ABSOLUTE;
    if (res.LABEL_PRODUCT_REVENUE)
      Languages.LABEL_PRODUCT_REVENUE = res.LABEL_PRODUCT_REVENUE;
    else
      Languages.LABEL_PRODUCT_REVENUE = DefaultLanguages.LABEL_PRODUCT_REVENUE;
    if (res.LABEL_PRODUCT_VOLUMES)
      Languages.LABEL_PRODUCT_VOLUMES = res.LABEL_PRODUCT_VOLUMES;
    else
      Languages.LABEL_PRODUCT_VOLUMES = DefaultLanguages.LABEL_PRODUCT_VOLUMES;
    if (res.LABEL_PREDICTIVE_FORECAST)
      Languages.LABEL_PREDICTIVE_FORECAST = res.LABEL_PREDICTIVE_FORECAST;
    else
      Languages.LABEL_PREDICTIVE_FORECAST = DefaultLanguages.LABEL_PREDICTIVE_FORECAST;
    if (res.LABEL_SKU_SPLITS_BASED)
      Languages.LABEL_SKU_SPLITS_BASED = res.LABEL_SKU_SPLITS_BASED;
    else
      Languages.LABEL_SKU_SPLITS_BASED = DefaultLanguages.LABEL_SKU_SPLITS_BASED;
    if (res.LABEL_BRAND)
      Languages.LABEL_BRAND = res.LABEL_BRAND;
    else
      Languages.LABEL_BRAND = DefaultLanguages.LABEL_BRAND;
    if (res.LABEL_SKU_MATERIAL)
      Languages.LABEL_SKU_MATERIAL = res.LABEL_SKU_MATERIAL;
    else
      Languages.LABEL_SKU_MATERIAL = DefaultLanguages.LABEL_SKU_MATERIAL;
    if (res.LABEL_CUSTOMER)
      Languages.LABEL_CUSTOMER = res.LABEL_CUSTOMER;
    else
      Languages.LABEL_CUSTOMER = DefaultLanguages.LABEL_CUSTOMER;
      
    Languages.LABEL_FILE_NAME =res.LABEL_FILE_NAME?res.LABEL_FILE_NAME: DefaultLanguages.LABEL_FILE_NAME;
    Languages.LABEL_STATUS = res.LABEL_STATUS?res.LABEL_STATUS:DefaultLanguages.LABEL_STATUS;
    Languages.LABEL_COMMENTS = res.LABEL_COMMENTS?res.LABEL_COMMENTS:DefaultLanguages.LABEL_COMMENTS;
      Languages.LABEL_ACTION = res.LABEL_ACTION?res.LABEL_ACTION:DefaultLanguages.LABEL_ACTION;
      Languages.LABEL_PROCESSING_ACTION = res.LABEL_PROCESSING_ACTION?res.LABEL_PROCESSING_ACTION:DefaultLanguages.LABEL_PROCESSING_ACTION;
      Languages.LABEL_FILE_ARRIVAL_TIME = res.LABEL_FILE_ARRIVAL_TIME?res.LABEL_FILE_ARRIVAL_TIME: DefaultLanguages.LABEL_FILE_ARRIVAL_TIME;
      Languages.LABEL_FILE_PROCESSED_TIME = res.LABEL_FILE_PROCESSED_TIME?res.LABEL_FILE_PROCESSED_TIME:DefaultLanguages.LABEL_FILE_PROCESSED_TIME;
      Languages.LABEL_DOWNLOAD = res.LABEL_DOWNLOAD?res.LABEL_DOWNLOAD:DefaultLanguages.LABEL_DOWNLOAD;
      Languages.LABEL_ERROR_REPORT =res.LABEL_ERROR_REPORT?res.LABEL_ERROR_REPORT: DefaultLanguages.LABEL_ERROR_REPORT;
      Languages.LABEL_RETRY =res.LABEL_RETRY?res.LABEL_RETRY: DefaultLanguages.LABEL_RETRY;
      Languages.LABEL_START = res.LABEL_START?res.LABEL_START:DefaultLanguages.LABEL_START;
      Languages.LABEL_FILE_GENERATION_TIME = res.LABEL_FILE_GENERATION_TIME?res.LABEL_FILE_GENERATION_TIME:DefaultLanguages.LABEL_FILE_GENERATION_TIME;
      Languages.LABEL_DOWNLOAD = res.LABEL_DOWNLOAD?res.LABEL_DOWNLOAD:DefaultLanguages.LABEL_DOWNLOAD;
      Languages.LABEL_PROGRESS =  res.LABEL_PROGRESS? res.LABEL_PROGRESS:DefaultLanguages.LABEL_PROGRESS;
      Languages.LABEL_PROCESSING_HISTORY =res.LABEL_PROCESSING_HISTORY?res.LABEL_PROCESSING_HISTORY: DefaultLanguages.LABEL_PROCESSING_HISTORY;
      Languages.LABEL_VIEW = res.LABEL_VIEW?res.LABEL_VIEW:DefaultLanguages.LABEL_VIEW;
      Languages.LABEL_USER_LEVEL=res.LABEL_USER_LEVEL?res.LABEL_USER_LEVEL:DefaultLanguages.LABEL_USER_LEVEL;
      Languages.LABEL_START_DATE = res.LABEL_START_DATE?res.LABEL_START_DATE:DefaultLanguages.LABEL_START_DATE;
      Languages.LABEL_END_DATE = res.LABEL_END_DATE?res.LABEL_END_DATE:DefaultLanguages.LABEL_END_DATE;
      Languages.LABEL_FROM_DATE = res.LABEL_FROM_DATE?res.LABEL_FROM_DATE: DefaultLanguages.LABEL_FROM_DATE;
      Languages.LABEL_TO_DATE = res.LABEL_TO_DATE?res.LABEL_TO_DATE: DefaultLanguages.LABEL_TO_DATE;
      Languages.LABEL_ADD = res.LABEL_ADD?res.LABEL_ADD:DefaultLanguages.LABEL_ADD;
      Languages.LABEL_EDIT = res.LABEL_ADD?res.LABEL_EDIT:DefaultLanguages.LABEL_EDIT;
      Languages.LABEL_UPDATE = res.LABEL_ADD?res.LABEL_UPDATE:DefaultLanguages.LABEL_UPDATE;
      Languages.LABEL_CANCEL = res.LABEL_CANCEL?res.LABEL_CANCEL:DefaultLanguages.LABEL_CANCEL;

      Languages.LABEL_PROCESSALL_MASTER= res.LABEL_PROCESSALL_MASTER?res.LABEL_PROCESSALL_MASTER:DefaultLanguages.LABEL_PROCESSALL_MASTER;
      Languages.LABEL_SAVE= res.LABEL_SAVE?res.LABEL_SAVE:DefaultLanguages.LABEL_SAVE;

      Languages.LABEL_GENERATED_REPORTS= res.LABEL_GENERATED_REPORTS?res.LABEL_GENERATED_REPORTS:DefaultLanguages.LABEL_GENERATED_REPORTS;
      Languages.LABEL_GENERATE_REPORT=res.LABEL_GENERATE_REPORT?res.LABEL_GENERATE_REPORT:DefaultLanguages.LABEL_GENERATE_REPORT;
      Languages.LABEL_PROFILE_DETAILS=res.LABEL_PROFILE_DETAILS?res.LABEL_PROFILE_DETAILS:DefaultLanguages.LABEL_PROFILE_DETAILS
      Languages.LABEL_CHANGE_LEVEL=res.LABEL_CHANGE_LEVEL?res.LABEL_CHANGE_LEVEL:DefaultLanguages.LABEL_CHANGE_LEVEL
      Languages.LABEL_LOGOUT=res.LABEL_LOGOUT?res.LABEL_LOGOUT:DefaultLanguages.LABEL_LOGOUT;
      Languages.LABEL_CHANGE_PROFIT_CENTER=res.LABEL_CHANGE_PROFIT_CENTER?res.LABEL_CHANGE_PROFIT_CENTER:DefaultLanguages.LABEL_CHANGE_PROFIT_CENTER
      Languages.LABEL_CHANGE= res.LABEL_CHANGE?res.LABEL_CHANGE:DefaultLanguages.LABEL_CHANGE;

      Languages.LABEL_REQUESTED_ID= res.LABEL_REQUESTED_ID?res.LABEL_REQUESTED_ID:DefaultLanguages.LABEL_REQUESTED_ID;
      Languages.LABEL_REQUESTED_DATE= res.LABEL_REQUESTED_DATE?res.LABEL_REQUESTED_DATE:DefaultLanguages.LABEL_REQUESTED_DATE;
      Languages.LABEL_REPORT_GENERATED_DATE= res.LABEL_REPORT_GENERATED_DATE?res.LABEL_REPORT_GENERATED_DATE:DefaultLanguages.LABEL_REPORT_GENERATED_DATE;

      Languages.LABEL_OUTPUT_CREATED_STATUS = res.LABEL_OUTPUT_CREATED_STATUS ?res.LABEL_OUTPUT_CREATED_STATUS :DefaultLanguages.LABEL_OUTPUT_CREATED_STATUS ;
      Languages.LABEL_OUTPUT_INITIATED_STATUS = res.LABEL_OUTPUT_INITIATED_STATUS ?res.LABEL_OUTPUT_INITIATED_STATUS :DefaultLanguages.LABEL_OUTPUT_INITIATED_STATUS ;
      Languages.LABEL_OUTPUT_COMPLETED_STATUS = res.LABEL_OUTPUT_COMPLETED_STATUS ?res.LABEL_OUTPUT_COMPLETED_STATUS :DefaultLanguages.LABEL_OUTPUT_COMPLETED_STATUS ;
      Languages.LABEL_OUTPUT_FAILED_STATUS = res.LABEL_OUTPUT_FAILED_STATUS ?res.LABEL_OUTPUT_FAILED_STATUS :DefaultLanguages.LABEL_OUTPUT_FAILED_STATUS ;

      Languages.LABEL_PROCESSED_SUCCESS_STATUS = res.LABEL_PROCESSED_SUCCESS_STATUS ?res.LABEL_PROCESSED_SUCCESS_STATUS :DefaultLanguages.LABEL_PROCESSED_SUCCESS_STATUS ;
      Languages.LABEL_PROCESS_FAILED_STATUS = res.LABEL_PROCESS_FAILED_STATUS ?res.LABEL_PROCESS_FAILED_STATUS :DefaultLanguages.LABEL_PROCESS_FAILED_STATUS ;
      Languages.LABEL_UNDER_PROCESS_STATUS = res.LABEL_UNDER_PROCESS_STATUS ?res.LABEL_UNDER_PROCESS_STATUS :DefaultLanguages.LABEL_UNDER_PROCESS_STATUS ;
      Languages.LABEL_PENDING_PROCESS_STATUS = res.LABEL_PENDING_PROCESS_STATUS ?res.LABEL_PENDING_PROCESS_STATUS :DefaultLanguages.LABEL_PENDING_PROCESS_STATUS ;
      Languages.LABEL_FILE_RECEIVED_STATUS = res.LABEL_FILE_RECEIVED_STATUS ?res.LABEL_FILE_RECEIVED_STATUS :DefaultLanguages.LABEL_FILE_RECEIVED_STATUS ;
      Languages.LABEL_FILE_INJECTION_PROCESS_STATUS = res.LABEL_FILE_INJECTION_PROCESS_STATUS ?res.LABEL_FILE_INJECTION_PROCESS_STATUS :DefaultLanguages.LABEL_FILE_INJECTION_PROCESS_STATUS ;
      Languages. LABEL_FILE_INJECTION_FAILED_STATUS = res. LABEL_FILE_INJECTION_FAILED_STATUS ?res. LABEL_FILE_INJECTION_FAILED_STATUS :DefaultLanguages. LABEL_FILE_INJECTION_FAILED_STATUS ;

      Languages.LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS = res.LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS ?res.LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS :DefaultLanguages.LABEL_DOWNLOAD_REQUEST_ACCEPTED_STATUS ;
      Languages.LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS = res.LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS ?res.LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS :DefaultLanguages.LABEL_DOWNLOAD_REQUEST_SUCCESSFUL_STATUS ;
      Languages.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS = res.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS ?res.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS :DefaultLanguages.LABEL_FORECAST_UPDATE_SUCCESSFUL_STATUS ;
      Languages.LABEL_SELECT_FINANCIAL_YEAR = res.LABEL_SELECT_FINANCIAL_YEAR ?res.LABEL_SELECT_FINANCIAL_YEAR :DefaultLanguages.LABEL_SELECT_FINANCIAL_YEAR ;
      Languages.LABEL_SAME_UOM = res.LABEL_SAME_UOM ?res.LABEL_SAME_UOM :DefaultLanguages.LABEL_SAME_UOM ;
      Languages.LABEL_LEVEL_NOT_NULL = res.LABEL_LEVEL_NOT_NULL ?res.LABEL_LEVEL_NOT_NULL :DefaultLanguages.LABEL_LEVEL_NOT_NULL ;

      Languages.LABEL_MT_KL= res.LABEL_MT_KL?res.LABEL_MT_KL:DefaultLanguages.LABEL_MT_KL;
      Languages.LABEL_KG_LT_Absolute= res.LABEL_KG_LT_Absolute?res.LABEL_KG_LT_Absolute:DefaultLanguages.LABEL_KG_LT_Absolute;
      Languages.LABEL_MT_KL_1KEACHES= res.LABEL_MT_KL_1KEACHES?res.LABEL_MT_KL_1KEACHES:DefaultLanguages.LABEL_MT_KL_1KEACHES;
      Languages.LABEL_MT_KL_EACHES_ABSOLUTE= res.LABEL_MT_KL_EACHES_ABSOLUTE?res.LABEL_MT_KL_EACHES_ABSOLUTE:DefaultLanguages.LABEL_MT_KL_EACHES_ABSOLUTE;

      Languages.LABEL_MILLIONS=res.LABEL_MILLIONS?res.LABEL_MILLIONS:DefaultLanguages.LABEL_MILLIONS;
      Languages.LABEL_REFRESHING_DATA=res.LABEL_REFRESHING_DATA?res.LABEL_REFRESHING_DATA:DefaultLanguages.LABEL_REFRESHING_DATA;
      Languages.LABEL_ALL_VALUES_IN_MXN=res.LABEL_ALL_VALUES_IN_MXN?res.LABEL_ALL_VALUES_IN_MXN:DefaultLanguages.LABEL_ALL_VALUES_IN_MXN;
      Languages.LABEL_ALL_VALUES_IN=res.LABEL_ALL_VALUES_IN?res.LABEL_ALL_VALUES_IN:DefaultLanguages.LABEL_ALL_VALUES_IN;

      Languages.LABEL_NO_DATA_AVAILABLE=res.LABEL_NO_DATA_AVAILABLE?res.LABEL_NO_DATA_AVAILABLE:DefaultLanguages.LABEL_NO_DATA_AVAILABLE;
      Languages.LABEL_SBU=res.LABEL_SBU?res.LABEL_SBU:DefaultLanguages.LABEL_SBU;
      Languages.LABEL_FILTER=res.LABEL_FILTER?res.LABEL_FILTER:DefaultLanguages.LABEL_FILTER;
      Languages.LABEL_STATIC_OPQ=res.LABEL_STATIC_OPQ?res.LABEL_STATIC_OPQ:DefaultLanguages.LABEL_STATIC_OPQ;
      Languages.LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER=res.LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER?res.LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER:DefaultLanguages.LABEL_FORECAST_DATA_IN_ABSOLUTE_NUMBER;

      Languages.LABEL_DYNAMIC_ABC=res.LABEL_DYNAMIC_ABC?res.LABEL_DYNAMIC_ABC:DefaultLanguages.LABEL_DYNAMIC_ABC;
      Languages.LABEL_SEARCHBY=res.LABEL_SEARCHBY?res.LABEL_SEARCHBY:DefaultLanguages.LABEL_SEARCHBY;
      Languages.LABEL_MOLECULE=res.LABEL_MOLECULE?res.LABEL_MOLECULE:DefaultLanguages.LABEL_MOLECULE;
      Languages.LABEL_CLEAR=res.LABEL_CLEAR?res.LABEL_CLEAR:DefaultLanguages.LABEL_CLEAR;
      Languages.LABEL_APPLY=res.LABEL_APPLY?res.LABEL_APPLY:DefaultLanguages.LABEL_APPLY;
      Languages.LABEL_SALES_QTY=res.LABEL_SALES_QTY?res.LABEL_SALES_QTY:DefaultLanguages.LABEL_SALES_QTY;

      Languages.LABEL_POG=res.LABEL_POG?res.LABEL_POG:DefaultLanguages.LABEL_POG;
      Languages.LABEL_NET_PRICE=res.LABEL_NET_PRICE?res.LABEL_NET_PRICE:DefaultLanguages.LABEL_NET_PRICE;
      Languages.LABEL_GROSS_PRICE=res.LABEL_GROSS_PRICE?res.LABEL_GROSS_PRICE:DefaultLanguages.LABEL_GROSS_PRICE;
      Languages.LABEL_EVENT=res.LABEL_EVENT?res.LABEL_EVENT:DefaultLanguages.LABEL_EVENT;
      Languages.LABEL_LEVEL=res.LABEL_LEVEL?res.LABEL_LEVEL:DefaultLanguages.LABEL_LEVEL;
      Languages.LABEL_CYCLE_NAME=res.LABEL_CYCLE_NAME?res.LABEL_CYCLE_NAME:DefaultLanguages.LABEL_CYCLE_NAME
      Languages.LABEL_SELECT_ALL=res.LABEL_SELECT_ALL?res.LABEL_SELECT_ALL:DefaultLanguages.LABEL_SELECT_ALL;
      Languages.LABEL_UNSAVED_CHANGES=res.LABEL_UNSAVED_CHANGES?res.LABEL_UNSAVED_CHANGES:DefaultLanguages.LABEL_UNSAVED_CHANGES;
      Languages.LABEL_NO=res.LABEL_NO?res.LABEL_NO:DefaultLanguages.LABEL_NO;
      Languages.LABEL_YES_SURE=res.LABEL_YES_SURE?res.LABEL_YES_SURE:DefaultLanguages.LABEL_YES_SURE;
      Languages.LABEL_SCROLL_TO_BOTTOM=res.LABEL_SCROLL_TO_BOTTOM?res.LABEL_SCROLL_TO_BOTTOM:DefaultLanguages.LABEL_SCROLL_TO_BOTTOM;
      Languages.LABEL_SCROLL_TO__TOP=res.LABEL_SCROLL_TO__TOP?res.LABEL_SCROLL_TO__TOP:DefaultLanguages.LABEL_SCROLL_TO__TOP;
      Languages.LABEL_ADVANCE_FILTER=res.LABEL_ADVANCE_FILTER?res.LABEL_ADVANCE_FILTER:DefaultLanguages.LABEL_ADVANCE_FILTER;
      Languages.LABEL_CLEAR_FILTER=res.LABEL_CLEAR_FILTER?res.LABEL_CLEAR_FILTER:DefaultLanguages.LABEL_CLEAR_FILTER;
      Languages.LABEL_AREA=res.LABEL_AREA?res.LABEL_AREA:DefaultLanguages.LABEL_AREA;
      Languages.LABEL_JAN=res.LABEL_JAN?res.LABEL_JAN:DefaultLanguages.LABEL_JAN;
      Languages.LABEL_FEB=res.LABEL_FEB?res.LABEL_FEB:DefaultLanguages.LABEL_FEB;
      Languages.LABEL_MAR=res.LABEL_MAR?res.LABEL_MAR:DefaultLanguages.LABEL_MAR;
      Languages.LABEL_APR=res.LABEL_APR?res.LABEL_APR:DefaultLanguages.LABEL_APR; 
      Languages.LABEL_MAY=res.LABEL_MAY?res.LABEL_MAY:DefaultLanguages.LABEL_MAY;
      Languages.LABEL_JUN=res.LABEL_JUN?res.LABEL_JUN:DefaultLanguages.LABEL_JUN;
      Languages.LABEL_JUL=res.LABEL_JUL?res.LABEL_JUL:DefaultLanguages.LABEL_JUL;
      Languages.LABEL_AUG=res.LABEL_AUG?res.LABEL_AUG:DefaultLanguages.LABEL_AUG;
      Languages.LABEL_SEP=res.LABEL_SEP?res.LABEL_SEP:DefaultLanguages.LABEL_SEP;
      Languages.LABEL_OCT=res.LABEL_OCT?res.LABEL_OCT:DefaultLanguages.LABEL_OCT;
      Languages.LABEL_NOV=res.LABEL_NOV?res.LABEL_NOV:DefaultLanguages.LABEL_NOV;
      Languages.LABEL_DEC=res.LABEL_DEC?res.LABEL_DEC:DefaultLanguages.LABEL_DEC;
      this.setMonthConstants(1,Languages.LABEL_JAN);
      this.setMonthConstants(2,Languages.LABEL_FEB);
      this.setMonthConstants(3,Languages.LABEL_MAR);
      this.setMonthConstants(4,Languages.LABEL_APR);
      this.setMonthConstants(5,Languages.LABEL_MAY);
      this.setMonthConstants(6,Languages.LABEL_JUN);  
      this.setMonthConstants(7,Languages.LABEL_JUL);
      this.setMonthConstants(8,Languages.LABEL_AUG);
      this.setMonthConstants(9,Languages.LABEL_SEP);
      this.setMonthConstants(10,Languages.LABEL_OCT);
      this.setMonthConstants(11,Languages.LABEL_NOV);
      this.setMonthConstants(12,Languages.LABEL_DEC);  
      /* set constants in fiscalmonth */
      this.setMonthConstantsInFiscalMonth(10,Languages.LABEL_JAN);
      this.setMonthConstantsInFiscalMonth(11,Languages.LABEL_FEB);
      this.setMonthConstantsInFiscalMonth(12,Languages.LABEL_MAR);
      this.setMonthConstantsInFiscalMonth(1,Languages.LABEL_APR);
      this.setMonthConstantsInFiscalMonth(2,Languages.LABEL_MAY);
      this.setMonthConstantsInFiscalMonth(3,Languages.LABEL_JUN);  
      this.setMonthConstantsInFiscalMonth(4,Languages.LABEL_JUL);
      this.setMonthConstantsInFiscalMonth(5,Languages.LABEL_AUG);
      this.setMonthConstantsInFiscalMonth(6,Languages.LABEL_SEP);
      this.setMonthConstantsInFiscalMonth(7,Languages.LABEL_OCT);
      this.setMonthConstantsInFiscalMonth(8,Languages.LABEL_NOV);
      this.setMonthConstantsInFiscalMonth(9,Languages.LABEL_DEC); 
      
        
      
      Languages.LABEL_UPDATED_WINDOW_MSG=res.LABEL_UPDATED_WINDOW_MSG?res.LABEL_UPDATED_WINDOW_MSG:DefaultLanguages.LABEL_UPDATED_WINDOW_MSG;
      Languages.LABEL_PROCEED_CONFIRMATION=res.LABEL_PROCEED_CONFIRMATION?res.LABEL_PROCEED_CONFIRMATION:DefaultLanguages.LABEL_PROCEED_CONFIRMATION;
      Languages.LABEL_WISH_TO=res.LABEL_WISH_TO?res.LABEL_WISH_TO:DefaultLanguages.LABEL_WISH_TO;
      Languages.LABEL_SELECT_PC_AS=res.LABEL_SELECT_PC_AS?res.LABEL_SELECT_PC_AS:DefaultLanguages.LABEL_SELECT_PC_AS;
      Languages.LABEL_PREFERRED_PROFIT_CENTER=res.LABEL_PREFERRED_PROFIT_CENTER?res.LABEL_PREFERRED_PROFIT_CENTER:DefaultLanguages.LABEL_PREFERRED_PROFIT_CENTER;
      Languages.LABEL_FUTURE_REFERENCE=res.LABEL_FUTURE_REFERENCE?res.LABEL_FUTURE_REFERENCE:DefaultLanguages.LABEL_FUTURE_REFERENCE;
      Languages.LABEL_FORECAST=res.LABEL_FORECAST?res.LABEL_FORECAST:DefaultLanguages.LABEL_FORECAST;
      Languages.LABEL_PARTICULARS=res.LABEL_PARTICULARS?res.LABEL_PARTICULARS:DefaultLanguages.LABEL_PARTICULARS;
      Languages.LABEL_PARTICULARS1=res.LABEL_PARTICULARS1?res.LABEL_PARTICULARS1:DefaultLanguages.LABEL_PARTICULARS1;
      Languages.LABEL_PARTICULARS2=res.LABEL_PARTICULARS2?res.LABEL_PARTICULARS2:DefaultLanguages.LABEL_PARTICULARS2;
      Languages.LABEL_PARTICULARS3=res.LABEL_PARTICULARS3?res.LABEL_PARTICULARS3:DefaultLanguages.LABEL_PARTICULARS3;
      Languages.LABEL_VOLUME=res.LABEL_VOLUME?res.LABEL_VOLUME:DefaultLanguages.LABEL_VOLUME;
      Languages.LABEL_TOTAL=res.LABEL_TOTAL?res.LABEL_TOTAL:DefaultLanguages.LABEL_TOTAL;
      Languages.LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION=res.LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION?res.LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION:DefaultLanguages.LABEL_UNSAVEDCHANGES_LEAVE_PAGE_CONFIRMATION;
      Languages.LABEL_RETURN=res.LABEL_RETURN?res.LABEL_RETURN:DefaultLanguages.LABEL_RETURN;
      Languages.LABEL_NET=res.LABEL_NET?res.LABEL_NET:DefaultLanguages.LABEL_NET;
      Languages.LABEL_GROSS=res.LABEL_GROSS?res.LABEL_GROSS:DefaultLanguages.LABEL_GROSS;
      Languages.LABEL_THRESHOLD=res.LABEL_THRESHOLD?res.LABEL_THRESHOLD:DefaultLanguages.LABEL_THRESHOLD;
      Languages.LABEL_NEGATIVE_VALUES_NOT_ALLOWED=res.LABEL_NEGATIVE_VALUES_NOT_ALLOWED?res.LABEL_NEGATIVE_VALUES_NOT_ALLOWED:DefaultLanguages.LABEL_NEGATIVE_VALUES_NOT_ALLOWED;
      Languages.LABEL_NEGATIVE_FIGURES=res.LABEL_NEGATIVE_FIGURES?res.LABEL_NEGATIVE_FIGURES:DefaultLanguages.LABEL_NEGATIVE_FIGURES;
      Languages.LABEL_THRESHOLD_LIMIT_CROSSED=res.LABEL_THRESHOLD_LIMIT_CROSSED?res.LABEL_THRESHOLD_LIMIT_CROSSED:DefaultLanguages.LABEL_THRESHOLD_LIMIT_CROSSED;
      Languages.LABEL_LOCAL_TIME=res.LABEL_LOCAL_TIME?res.LABEL_LOCAL_TIME:DefaultLanguages.LABEL_LOCAL_TIME;
      Languages. LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION=res.LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION?res.LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION:DefaultLanguages.LABEL_MASTER_FILE_NOT_PROCESSED_POPUP_CONFIRMATION;
      Languages.LABEL_FILE_IS_PROCESSING_MESSAGE=res.LABEL_FILE_IS_PROCESSING_MESSAGE?res.LABEL_FILE_IS_PROCESSING_MESSAGE:DefaultLanguages.LABEL_FILE_IS_PROCESSING_MESSAGE;
      Languages.LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE=res.LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE?res.LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE:DefaultLanguages.LABEL_MASTER_FILES_ARE_PROCESSING_MESSAGE;
      Languages.LABEL_Y1_YEAR_VOLUME=res.LABEL_Y1_YEAR_VOLUME?res.LABEL_Y1_YEAR_VOLUME:DefaultLanguages.LABEL_Y1_YEAR_VOLUME;
      Languages.LABEL_MASTER_FILE_CONFIRMATION_MESSAGE=res.LABEL_MASTER_FILE_CONFIRMATION_MESSAGE?res.LABEL_MASTER_FILE_CONFIRMATION_MESSAGE:DefaultLanguages.LABEL_MASTER_FILE_CONFIRMATION_MESSAGE;
      Languages.LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE=res.LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE?res.LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE:DefaultLanguages.LABEL_MASTER_FILE_ALL_CONFIRMATION_MESSAGE;
  
      Languages.LABEL_SHOWING=res.LABEL_SHOWING?res.LABEL_SHOWING:DefaultLanguages.LABEL_SHOWING;
      Languages.LABEL_TO=res.LABEL_TO?res.LABEL_TO:DefaultLanguages.LABEL_TO;
      Languages.LABEL_OF=res.LABEL_OF?res.LABEL_OF:DefaultLanguages.LABEL_OF;
      Languages.LABEL_ENTRIES=res.LABEL_ENTRIES?res.LABEL_ENTRIES:DefaultLanguages.LABEL_ENTRIES;

    }
    
    setMonthConstants(i,valueToUpdate){
      const objIndex = MONTHS.findIndex((obj => obj.id == i));
      MONTHS[objIndex].month = valueToUpdate;
    }
    setMonthConstantsInFiscalMonth(i,valueToUpdate){
      const objIndex = FISCALMONTHS.findIndex((obj => obj.id == i));
      FISCALMONTHS[objIndex].month = valueToUpdate;
    }
    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigate([currentUrl])
        .then(() => {
          window.location.reload();
      });
    }
    fetchNotificationCount(){
      let geoCode = this.fetchCurrentGeoForNoti();
      let size =5;
      let page =0;
      if(    this.profile.level === "Territory Manager" ||
      this.profile.level === "Zonal Manager" ||
      this.profile.level === "Strategic Business Unit" ||
      this.profile.level === "Regional Manager" ||
      this.profile.level === "Area Manager"){
        this.adminNotificationService.viewAutoNotificationsUser(geoCode,page,size).subscribe((response:any)=>{
        this.unreadCount = response.unreadCount;
        this.fetchedNotificationContent=response.notifications.content;
      })
    }
    else if (
      this.profile.level === "SAP APO Admin" ||
      this.profile.level === "Profit Center" ||
      this.profile.level === "Profit Center Admin" ||
      this.profile.level === "Marketing Manager" ||
      this.profile.level === "Unconstrained Manager"
    ) {
      this.adminNotificationService.viewAutoNotificationsAdmin(page,size).subscribe((response:any)=>{
        this.unreadCount = response.unreadCount;
        this.fetchedNotificationContent=response.notifications.content;
      }) 
    }
    }
      fetchCurrentGeoForNoti() {
    if (this.defaultGeograhy && this.profile.level) {
      let geoCode = '';
      this.geography.forEach(geo => {
        if (geo.geoId === Number(this.defaultGeograhy)) {
          geoCode = geo.geoCode;
        }
      });
      if (this.profile.level === MARKETING_MANAGER) {
        return this.profile.ugdn;
      }      
      return `${geoCode}`;
    } else {
      return ''
    }
  }
  markedAsReadEvent(event){
    if(event)
      this.fetchNotificationCount();
  }
}
