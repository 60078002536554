import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastrMessageService {

  constructor(private toastrService: ToastrService) { }

  successMessage(msg: string) {
    if (msg && msg !== '') {
      this.toastrService.success(msg);
    }
  }
  warningMessage(msg: string) {
    if (msg && msg !== '') {
      this.toastrService.warning(msg);
    }
  }
  errorMessage(msg: string) {
    if (msg && msg !== '') {
      this.toastrService.error(msg);
    }
  }
}
