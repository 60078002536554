import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpInterceptorService } from "./core/interceptors/http-interceptor.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { NgSelectModule } from "@ng-select/ng-select";
import { LoaderComponent } from "./shared/loader/loader.component";
import { NgHttpLoaderModule } from "ng-http-loader";
import { SharedModule } from "./shared/shared.module";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { MatDialogModule } from "@angular/material";
import { ZoomDetectionDirective } from './core/helpers/zoom-detection.directive';

@NgModule({
  declarations: [AppComponent, ZoomDetectionDirective],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    NgSelectModule,
    NgxSpinnerModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      preventDuplicates: true,
      timeOut: 5000,
    }),
    NgHttpLoaderModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  entryComponents: [LoaderComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
