import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Languages } from 'src/app/core/constants/languages.constants';

@Component({
  selector: 'app-session-time-out',
  templateUrl: './session-time-out.component.html',
  styleUrls: ['./session-time-out.component.scss']
})
export class SessionTimeOutComponent implements OnInit {

  @Output() response = new EventEmitter();
  @Input() description;
  languages=Languages;
  status: any;
  constructor(public modal: NgbActiveModal,
    ) { }

  ngOnInit() {
    //oninit
  }

  submit() {
    this.response.emit(true);
    this.modal.close();
  }

  onOk() {
    this.modal.close();
  }

}
