import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CommonProductRevenueComponent } from "./common-product-revenue/common-product-revenue.component";
import { CommonProductVolumeComponent } from "./common-product-volume/common-product-volume.component";
import { CommonPredictiveForecastComponent } from "./common-predictive-forecast/common-predictive-forecast.component";
import { CommonSkuSplitsBasedComponent } from "./common-sku-splits-based/common-sku-splits-based.component";
import { HotTableModule } from "@handsontable/angular";
import { NgbModule, NgbCollapseModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonDownloadedReportsComponent } from "./common-downloaded-reports/common-downloaded-reports.component";
import { CommonYOneTableComponent } from "./common-y-one-table/common-y-one-table.component";

@NgModule({
  declarations: [
    CommonProductRevenueComponent,
    CommonProductVolumeComponent,
    CommonPredictiveForecastComponent,
    CommonSkuSplitsBasedComponent,
    CommonDownloadedReportsComponent,
    CommonYOneTableComponent,
  ],
  exports: [
    CommonProductRevenueComponent,
    CommonProductVolumeComponent,
    CommonPredictiveForecastComponent,
    CommonSkuSplitsBasedComponent,
    CommonDownloadedReportsComponent,
    CommonYOneTableComponent,
  ],
  imports: [HotTableModule, CommonModule, NgbModule, NgbCollapseModule],
})
export class CommonTableModule {}
