import { Component, EventEmitter,  OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Observable, Subscription } from 'rxjs';
import { catchError,map, shareReplay } from 'rxjs/operators';
import { COMPLETED, PENDING } from 'src/app/core/constants/string.constants';
import { Languages, Timezone } from 'src/app/core/constants/languages.constants';
import { DownLoadReportsService } from 'src/app/core/services/download-reports.service';
import { TenantService } from 'src/app/core/services/tenant.service';
import { ToastrMessageService } from 'src/app/core/services/toast-message.service';
import { MARKETING_MANAGER } from 'src/app/core/constants/tenant.constants';

@Component({
  selector: 'app-common-downloaded-reports',
  templateUrl: './common-downloaded-reports.component.html',
  styleUrls: ['./common-downloaded-reports.component.scss']
})
export class CommonDownloadedReportsComponent implements OnInit {
  geoCode: any;
  profile = JSON.parse(localStorage.getItem('profile'));
  defaultGeography = localStorage.getItem('defaultGeography');
  reports: any[] = [];
  dataSubscriptionArr: Subscription[] = [];
  interval:any;
  geography;
  userLevel;
  levelId;
  reports$: Observable<any[]>;
  languages= Languages;
  page = 1;
  pageSize = 10;
  collectionSize = 0;
  @Output() reportCount = new EventEmitter<any>();
  errorObject: any;
  timeInterval: number=60*1000*2;
  timezone = Timezone;


  constructor(private downloadReportService: DownLoadReportsService,
    private route: ActivatedRoute,
    private router: Router,
    private toastMessageService: ToastrMessageService,
    private tenantService: TenantService) { }

  ngOnInit() {
    this.defaultGeography = localStorage.getItem('defaultGeography');
    this.geography = JSON.parse(localStorage.getItem('geographies'));
    const profile = JSON.parse(localStorage.getItem("profile"));
    this.geoCode = this.fetchCurrentGeo();
    this.route.queryParams.subscribe(params => {
      if (!profile.hasGeography) {
        this.userLevel = params.level;
        this.levelId = params.levelId;
      } else {
        this.router.navigate(['/' + this.tenantService.getTenant().toLowerCase()]);
      }
    });
    const page = this.page - 1;
     this.getRequestedReportsList(this.geoCode,page,this.pageSize);
     this.interval= setInterval(() => {
      this.getRequestedReportsList(this.geoCode,page,this.pageSize);

    },this.timeInterval);
  }
  getRequestedReportsList(geoCode,page,pageSize) {
    this.errorObject = null;
    this.reports$ =this.downloadReportService.fetchViewRequestedReports(geoCode,page,pageSize) 
    .pipe(map(data => {
    this.collectionSize = data.total;
    this.pageSize = data.pageSize;
    return data.content;
    }),catchError(err => {
      this.errorObject = err;
      console.log(err);
      return [];
    }),shareReplay());
 
  }

  getRequestedReportsList1(event) {
    this.errorObject = null;
    this.page = event;
    const page = this.page - 1;
    this.reports$ =this.downloadReportService.fetchViewRequestedReports(this.geoCode,page,this.pageSize) 
    .pipe(map(data => {
    this.pageSize = data.pageSize;
    return data.content;
    }),catchError(err1 => {
      this.errorObject = err1;
      console.log(err1);
      return []; 
    }),shareReplay());
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (!!this.dataSubscriptionArr && this.dataSubscriptionArr.length) {
      this.dataSubscriptionArr.forEach(sub => {
        sub.unsubscribe();
      });
    }
  } 
  downloadReport(requestCode) {
    this.downloadReportService.fetchDownloadedReport(requestCode).subscribe(response => {
      if (response.status === "PENDING") {
        this.toastMessageService.warningMessage("Report Request is " + response.status);
      } else if (response.status == "COMPLETED") {
      this.downloadMyReportFile(response.reportDownloadPath);
      this.toastMessageService.successMessage("Report Downloaded successfully");
      }
    }, err => {
      console.log(err);   
    });
  }

  retryDownloadReport(requestCode){
    this.downloadReportService.retryDownloadReport(requestCode).subscribe(response => {
      if (response.status === PENDING) {
        this.toastMessageService.warningMessage("Report Request is " + response.status);
      } else if (response.status == COMPLETED) {
      this.downloadMyReportFile(response.reportDownloadPath);
      this.toastMessageService.successMessage("Report Downloaded successfully");
      }
      const page = this.page - 1;
      this.getRequestedReportsList(this.geoCode,page,this.pageSize);
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval= setInterval(() => {
        this.getRequestedReportsList(this.geoCode,page,this.pageSize);
      },this.timeInterval);
    }, err => {
      console.log(err);   
    });
  }
  downloadMyReportFile(downloadPath) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', downloadPath);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  fetchCurrentGeo() {
    if (this.defaultGeography && !this.userLevel) {
      let geoCode = '';
      this.geography.forEach(geo => {
        if (geo.geoId === Number(this.defaultGeography)) {
          geoCode = geo.geoCode;
        }
      });
      if (this.profile.level === MARKETING_MANAGER) {
        return this.profile.ugdn;
      }      
      return `${geoCode}`;
    } else {
      return ''
    }
  }
}



