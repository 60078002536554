import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TenantService } from '../services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class AdoptionStatsReportGuard implements CanActivate {

  constructor(private router: Router, private tenantService: TenantService, private activatedRoute: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('token');
    if (token) {
      const authCode = next.queryParams.code;
      const embedURL = localStorage.getItem("adoptionStatsReportsEmbedURL");
      if (authCode && !embedURL) {        
        return true;
      } else {
        const defaultTenant = localStorage.getItem('defaultTenant');
        const profile = JSON.parse(localStorage.getItem('profile'));
        const assignedLevel = localStorage.getItem('assignedLevel');
        const defaultGeography = localStorage.getItem('defaultGeography');
        const tenant = this.tenantService.getTenant();
        if (defaultTenant) {
          if (profile.assignedLevels.length > 1 && !assignedLevel) {
            this.router.navigate(['/level']);
            return false;
          } else if (profile.hasGeography && !defaultGeography) {
            this.router.navigate(['/geography']);
            return false;
          } else if (!profile.hasGeography) {
            this.router.navigate(["/admin"]);
            return false;
          } else if (((profile.assignedLevels.length > 1 && assignedLevel) || profile.assignedLevels.length === 1) && defaultGeography && profile.hasGeography) {
            this.router.navigate(["/" + tenant.toLowerCase() + "/forecasting"]);
            return false;
          }
        } else {
          this.router.navigate(['/profit-center']);
          return false;
        }
      }
    } else {
      this.router.navigate(['']);
    }
  }

}
