import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FilterApiService {

  constructor(private http: HttpClient) { }

  getFinancialYear(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/financial-years`);
  }

  getMaterialGroup(financialYear): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/financial-years/${financialYear}/material-groups`);
  }

  getProductBrand(materialGroup): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/material-groups/${materialGroup}/product-brands`);
  }

  getSKU(productBrand): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/product-brands/${productBrand}/skus`);
  }

  getGeo(geoId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/geographies/${geoId}/children`);
  }

  getErrorCategory(productBrandId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/product-brands/${productBrandId}/error-category`);
  }

  getGeoByLevel(level): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/geographies/${level}`);
  }

  getFilterMasters(geoId?, level?): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/masters/forecasting-filters?geo=${geoId}&level=${level}`);
  }

}
