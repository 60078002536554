import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { map, distinctUntilChanged } from "rxjs/operators";
import { Router } from "@angular/router";
import { Utility } from "../../shared/util/utility";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  tenants = ["AF", "SWAL"];
  role:any;

  public currentUser: Observable<any>;
  currentUserSubject = new BehaviorSubject<any>(localStorage.getItem("token"));

  constructor(private http: HttpClient, private router: Router, private utility : Utility) {
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getUser() {
    return this.currentUserSubject.asObservable();
  }

  getRole(){
    return this.role;
  }

  setRole(data){
    this.role = data;
  }

  isLoggedIn() {
    return this.getUser().pipe(
      distinctUntilChanged(),
      map((user) => !!user)
    );
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  getADRedirectUrl(): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/public/api/azure/sso-authenticate-uri`
    );
  }

  authenticateUser(authCode): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/public/api/azure/sso-authenticate`, authCode)
      .pipe(
        map((user) => {
          this.setLocalStrorage(user);
          return user;
        })
      );
  }

  login(userData): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}/public/api/authenticate-employee`, userData)
      .pipe(
        map((user) => {
          this.setLocalStrorage(user);
          return user;
        })
      );
  }

  setDefaultTenant(tenant): Observable<any> {
    return this.http.post(`${environment.apiUrl}/api/tenants/default`, tenant);
  }

  getTenantData(tenant): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/tenants/${tenant}`).pipe(
      map((tenantToken) => {
        localStorage.setItem("token", tenantToken["token"]);
        return tenantToken;
      })
    );
  }

  getAuthorities(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/authorities`).pipe(
      map((authority) => {
         this.setRole(authority["roles"])
        localStorage.setItem("authorities", authority["roles"]);
      })
    );
  }
  //Profile get service component
  getUserProfile(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/profile`).pipe(
      map((profile:any) => {
        localStorage.setItem(
          "geographies",
          JSON.stringify(profile["geographies"])
        );

        let profileData = {
          username  : this.utility.decrypt(profile.username),
          name : profile.name,
          email  :this.utility.decrypt(profile.email),
          level : profile.level,
          assignedLevels : profile.assignedLevels,
          ugdn : this.utility.decrypt(profile.ugdn),
          hasGeography : profile.hasGeography,
          geographies : profile.geographies,
          timezone : profile.timezone,
          token : profile.token,
          language : profile.language
        }
        localStorage.setItem("profile", JSON.stringify(profileData));
        localStorage.setItem("language", JSON.stringify(profile["language"]));
        return profile;
      })
    );
  }

  getLevelData(level): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/api/profile/level/${level}`)
      .pipe(
        map((profile:any) => {
          localStorage.setItem(
            "geographies",
            JSON.stringify(profile["geographies"])
          );
          let profileData = {
            username  : this.utility.decrypt(profile.username),
            name : profile.name,
            email  :this.utility.decrypt(profile.email),
            level : profile.level,
            assignedLevels : profile.assignedLevels,
            ugdn : this.utility.decrypt(profile.ugdn),
            hasGeography : profile.hasGeography,
            geographies : profile.geographies,
            timezone : profile.timezone,
            token : profile.token,
            language : profile.language
          }
          localStorage.setItem("profile", JSON.stringify(profileData)); 
          localStorage.setItem("language", JSON.stringify(profile["language"]));
          localStorage.setItem("token", profile["token"]);
          return profile;
        })
      );
  }

  logout(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/azure/logout`).pipe(
      map((uri) => {
        localStorage.clear();
        this.currentUserSubject.next(null);
        window.open(uri["url"], "_self");
      })
    );
  }

  logoutEmployee(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/logout-employee`).pipe(
      map((res) => {
        localStorage.clear();
        this.currentUserSubject.next(null);
        this.router.navigate(["/login"]);
      })
    );
  }

  errorLogout() {
    localStorage.clear();
    this.currentUserSubject.next(null);
  }

  isAdmin() {
    const pro = JSON.parse(localStorage.getItem("profile"));
    return !pro.hasGeography;
  }

  setLocalStrorage(user) {
    localStorage.setItem("token", user["token"]);
    localStorage.setItem("username", user["username"]);
    localStorage.setItem("tenants", JSON.stringify(user["tenants"]));
    this.currentUserSubject.next(user["token"]);
  }
}
