// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: "https://forecast.upl-ltd.com/salesforecasting-api",
  base64: "K9Y8NgGsmfJC0x2uvZsk5w==",
  // apiUrl: "http://localhost:8080",
};
