export const AF = 'AF';
export const SWAL = 'SWAL';
export const MEXICO = 'MEXICO';

export const PC="PC";

//level constants
export const MARKETING_MANAGER='Marketing Manager';

//promotionType constants
export const GEOGRAPHY='GEOGRAPHY';
export const USER='USER';