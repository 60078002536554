import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { TenantService } from "../services/tenant.service";

@Injectable({
  providedIn: "root",
})
export class LevelGuard implements CanActivate {
  constructor(private router: Router, private tenantService: TenantService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = localStorage.getItem("token");
    if (token) {
      const profile = JSON.parse(localStorage.getItem("profile"));
      const assignedLevel = localStorage.getItem("assignedLevel");
      const canView = localStorage.getItem("canViewLevel");
      const defaultTenant = localStorage.getItem("defaultTenant");
      const tenant = this.tenantService.getTenant();
      if (!defaultTenant) {
        this.router.navigate(["/profit-center"]);
        return false;
      } else if (
        profile.assignedLevels.length === 1 ||
        (profile.assignedLevels.length > 1 &&
          assignedLevel &&
          canView !== "true")
      ) {
        if (profile.hasGeography) {
          if (!localStorage.getItem("defaultGeography")) {
            this.router.navigate(["/geography"]);
          } else {
            this.router.navigate(["/" + tenant.toLowerCase() + "/forecasting"]);
          }
        } else {
          this.router.navigate(["/admin"]);
        }
        return false;
      } else {
        return true;
      }
    } else {
      this.router.navigate([""]);
    }
  }
}
