import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TenantService } from '../services/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard implements CanActivate {

  constructor(private router: Router, private tenantService: TenantService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('token');
    if (token) {
      const tenant = this.tenantService.getTenant();
      const profile = JSON.parse(localStorage.getItem('profile'));
      const assignedLevel = localStorage.getItem('assignedLevel');
      const defaultGeography = localStorage.getItem('defaultGeography');
      const defaultTenant = localStorage.getItem('defaultTenant');
      if (!defaultTenant) {
        this.router.navigate(['/profit-center']);
        return false;
      } else {
        if (tenant) {
          if (tenant.toLowerCase() !== next.routeConfig.path) {
            this.router.navigate(["/" + tenant.toLowerCase()]);
            return false;
          } else {
            if (profile.assignedLevels.length > 1 && !assignedLevel) {
              this.router.navigate(['/level']);
              return false;
            } else if (profile.hasGeography && !defaultGeography) {
              this.router.navigate(['/geography']);
              return false;
            } else if (!profile.hasGeography) {
              if (Object.keys(next.queryParams).length === 0) {
                this.router.navigate(['/admin']);
                return false;
              } else {
                const canViewForecast = localStorage.getItem('canGoToForecasting');
                if (canViewForecast === 'true') {
                  return true;
                } else {
                  this.router.navigate(['/admin']);
                  return false;
                }
              }
            } else if (profile.hasGeography) {
              if (next.queryParams && next.queryParams.level) {
                this.router.navigate([state.url])
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        } else {
          if (profile.assignedLevels.length > 1 && !assignedLevel) {
            this.router.navigate(['/level']);
            return false;
          } else if (profile.hasGeography && !defaultGeography) {
            this.router.navigate(['/geography']);
            return false;
          }
        }
      }
    } else {
      this.router.navigate(['']);
    }
  }

}
