import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavbarComponent } from "./navbar/navbar.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { SingleSelectComponent } from "./single-select/single-select.component";
import { MultiSelectComponent } from "./multi-select/multi-select.component";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { HotTableModule, HotTableRegisterer } from "@handsontable/angular";
import { RouterModule } from "@angular/router";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { LoaderComponent } from "./loader/loader.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";
import { ConfirmationMexicoDialogComponent } from "./mexico-confirmation-dialog/mexico-confirmation-dialog.component";
import { SessionTimeOutComponent } from "./session-time-out/session-time-out.component";
import { MultiSelectWoLabelComponent } from "./multi-select-wo-label/multi-select-wo-label.component";
import { CommonForecastingCycleComponent } from "./common-forecasting-cycle/common-forecasting-cycle.component";
import { ViewNotificationsComponent } from "./view-notifications/view-notifications.component";
import { SkuSplitsBasedDialogComponent } from "./sku-splits-based-dialog/sku-splits-based-dialog.component";
import { CommonTableModule } from "../private/tenants/common-tenant/common-tables/common-table.module";

@NgModule({
  declarations: [
    NavbarComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    ConfirmationDialogComponent,
    ConfirmationMexicoDialogComponent,
    LoaderComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    SessionTimeOutComponent,
    MultiSelectWoLabelComponent,
    CommonForecastingCycleComponent,
    ViewNotificationsComponent,
    SkuSplitsBasedDialogComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    ConfirmationMexicoDialogComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    SessionTimeOutComponent,
    SkuSplitsBasedDialogComponent,
  ],
  exports: [
    NavbarComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    ConfirmationDialogComponent,
    ConfirmationMexicoDialogComponent,
    LoaderComponent,
    InfoDialogComponent,
    SessionTimeOutComponent,
    MultiSelectWoLabelComponent,
    CommonForecastingCycleComponent,
    ViewNotificationsComponent,
  ],
  providers: [HotTableRegisterer],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    NgbTooltipModule,
    HotTableModule,
    ReactiveFormsModule,
    RouterModule,
    CommonTableModule,
  ],
})
export class SharedModule {}
