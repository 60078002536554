import { DefaultLanguages, Languages } from "./languages.constants";
export const MONTHS = [
    {
        month: Languages.LABEL_JAN,
        id: 1
    },
    {
        month: Languages.LABEL_FEB,
        id: 2
    },
    {
        month: Languages.LABEL_MAR,
        id: 3
    },
    {
        month: Languages.LABEL_APR,
        id: 4
    },
    {
        month: Languages.LABEL_MAY,
        id: 5
    },
    {
        month: Languages.LABEL_JUN,
        id: 6
    },
    {
        month: Languages.LABEL_JUL,
        id: 7
    },
    {
        month: Languages.LABEL_AUG,
        id: 8
    },
    {
        month: Languages.LABEL_SEP,
        id: 9
    },
    {
        month: Languages.LABEL_OCT,
        id: 10
    },
    {
        month: Languages.LABEL_NOV,
        id: 11
    },
    {
        month: Languages.LABEL_DEC,
        id: 12
    }
]
export const MONTHSENG = [
    {
        month: DefaultLanguages.LABEL_JAN,
        id: 1
    },
    {
        month: DefaultLanguages.LABEL_FEB,
        id: 2
    },
    {
        month: DefaultLanguages.LABEL_MAR,
        id: 3
    },
    {
        month: DefaultLanguages.LABEL_APR,
        id: 4
    },
    {
        month: DefaultLanguages.LABEL_MAY,
        id: 5
    },
    {
        month: DefaultLanguages.LABEL_JUN,
        id: 6
    },
    {
        month: DefaultLanguages.LABEL_JUL,
        id: 7
    },
    {
        month: DefaultLanguages.LABEL_AUG,
        id: 8
    },
    {
        month: DefaultLanguages.LABEL_SEP,
        id: 9
    },
    {
        month: DefaultLanguages.LABEL_OCT,
        id: 10
    },
    {
        month: DefaultLanguages.LABEL_NOV,
        id: 11
    },
    {
        month: DefaultLanguages.LABEL_DEC,
        id: 12
    }
]
export const COLUMN_HEADER = [
    {
        value: Languages.LABEL_JAN,
        key: 'JAN'
    },
    {
        value: Languages.LABEL_FEB,
        key: 'FEB'
    },
    {
        value: Languages.LABEL_MAR,
        key: 'MAR'
    },
    {
        value: Languages.LABEL_APR,
        key: 'APR'
    },
    {
        value: Languages.LABEL_MAY,
        key: 'MAY'
    },
    {
        value: Languages.LABEL_JUN,
        key: 'JUN'
    },
    {
        value: Languages.LABEL_JUL,
        key: 'JUL'
    },
    {
        value: Languages.LABEL_AUG,
        key: 'AUG'
    },
    {
        value: Languages.LABEL_SEP,
        key: 'SEP'
    },
    {
        value: Languages.LABEL_OCT,
        key: 'OCT'
    },
    {
        value: Languages.LABEL_NOV,
        key: 'NOV'
    },
    {
        value: Languages.LABEL_DEC,
        key: 'DEC'
    },
    {
        value: Languages.LABEL_PARTICULARS,
        key: 'PARTICULARS'
    },
    {
        value: Languages.LABEL_TOTAL,
        key: 'TOTAL'
    },
    {
        value: Languages.LABEL_PARTICULARS1,
        key: 'PARTICULARS1'
    },
    {
        value: Languages.LABEL_PARTICULARS1,
        key: 'PARTICULARS2'
    }
]





