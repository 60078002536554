import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Timezone } from 'src/app/core/constants/languages.constants';
import { CAN_CLOSE_CYCLE, CAN_DOWNLOAD_FORECASTING_CYCLE_ACTIVITIES, CAN_VIEW_FORECASTING_CYCLE } from 'src/app/core/constants/string.constants';
import { AdminForecastingCycleService } from 'src/app/core/services/admin-forecasting-cycle.service';
import { AdminWindowConfigureService } from 'src/app/core/services/admin-window-configure.service';
import { ToastrMessageService } from 'src/app/core/services/toast-message.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-common-forecasting-cycle',
  templateUrl: './common-forecasting-cycle.component.html',
  styleUrls: ['./common-forecasting-cycle.component.scss']
})
export class CommonForecastingCycleComponent implements OnInit {


  @Output() saveData = new EventEmitter();
  userActivity = [];
  code=[];
  tooltipData;
  authorities: string[];
  CAN_VIEW_FORECASTING_CYCLE = CAN_VIEW_FORECASTING_CYCLE;
  CAN_DOWNLOAD_FORECASTING_CYCLE_ACTIVITIES = CAN_DOWNLOAD_FORECASTING_CYCLE_ACTIVITIES;
  CAN_CLOSE_CYCLE = CAN_CLOSE_CYCLE;
  

  constructor(private forecastingCycleService: AdminForecastingCycleService,
    private adminWindowConfigureService: AdminWindowConfigureService,
    private toastrService: ToastrMessageService,
    private modalService: NgbModal) { }

  levels: any = [];
  activities: any = [];
  level: any=[];
  close;
  disabled = false;
  activeWindow: any = [];
  timezone = Timezone;

  ngOnInit() {
    this.authorities = localStorage.getItem('authorities').split(',');
    this.getLevels();
    this.getActiveWindow();
    this.getActivities();
  }

  getLevels() {
    this.adminWindowConfigureService.getCycleLevels().subscribe(res => {
      this.levels = res;
    });
  }

  getLevel(displayName) {
    return displayName.match(/\b(\w)/g).join('').toUpperCase();
  }

  closeCycle(e) {
    const dialogRef = this.modalService.open(ConfirmationDialogComponent, { centered: true });
    dialogRef.componentInstance.description = "You have selected Close Now option which will close the ongoing cycle for " + this.code + ", Are you sure you want to proceed with this?";
    dialogRef.componentInstance.response.subscribe(res => {
      if (res) {
        if (this.close) {
          this.forecastingCycleService.closeCycle().subscribe(res => {
            this.getActiveWindow();
            this.getActivities();
            this.toastrService.successMessage("Cycle Closed Successfully !");
          })
        }
      }
      else {
        this.close = !this.activities.cycleOpen;
      }
    });
  }

  getActiveWindow() {
    this.forecastingCycleService.getActiveWindow().subscribe(res => {
      this.level = [];
      this.code = [];
      this.activeWindow = res;
      this.activeWindow.forEach(element => {
        this.level.push(element.id);
        this.code.push(element.code);
      });
      if(this.level == []){          
        this.toastrService.warningMessage("No Active Window.")
      }
    });
  }

  getActivities() {
    this.forecastingCycleService.getActivities().subscribe(res => {
      this.activities = res;
      this.userActivity = this.activities.activities;
      this.close = !this.activities.cycleOpen;
    });
  }

  downloadActivityReport() {
    this.forecastingCycleService.downloadActivityReport().subscribe(res => {
      this.downloadFile(res);
    });
  }

  downloadFile(data) {
    const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.download = this.activities.cycleName?(this.activities.cycleName+'-Cycle_activity_report.xlsx'):'Cycle_activity_report.xlsx';
    anchor.href = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
  setTooltipData(data){
    this.tooltipData = {
      startTime: data.startTime,
      endTime: data.endTime,
      isClosed: data.isClosed,
      isDisabled: data.isDisabled,
      isOpen: data.isOpen,
      isSkipped: data.isSkipped
    };    
  }
}
