import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from "@angular/core";
import Handsontable from "handsontable";
import { HotTableComponent, HotTableRegisterer } from "@handsontable/angular";
import { getCurrentMonth } from "src/app/core/helpers/getCurrentMonth.helper";
import { NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { TenantService } from "src/app/core/services/tenant.service";
import { DashboardApiService } from "src/app/core/services/dashboard-api.service";
import { Languages } from "src/app/core/constants/languages.constants";
import { MEXICO } from "src/app/core/constants/tenant.constants";

@Component({
  selector: "app-common-predictive-forecast",
  templateUrl: "./common-predictive-forecast.component.html",
  styleUrls: ["./common-predictive-forecast.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonPredictiveForecastComponent implements OnInit, OnChanges {
  @ViewChild("predictiveHot", { static: false })
  predictiveHot: HotTableComponent;

  @Input() data;
  @Input() selectedFinancialYear;
  @Input() profile;
  @Input() userLevel;
  @Input() adminGeoId;
  @Input() selectedFilter;
  @Input() levelId;
  @Input() selectProductLength;
  @Input() financialYearList;

  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;

  isCollapsed = false;
  tenant;

  hotID = "PredictiveForcastingHot";
  defaultGeography;
  tableData: any;

  @Output() finYearChange = new EventEmitter();

  constructor(
    private hotRegisterer: HotTableRegisterer,
    private tenantService: TenantService,
    private dashBoardService: DashboardApiService
  ) { 
  }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes && changes.data && changes.data.currentValue) {
      this.tenant = this.tenantService.getTenant();
      this.hotSettings = null;
      if (this.tenant === MEXICO) {
        this.isCollapsed = true;
      } else {
        this.generateTable(this.data);
      }
    }
  }

  ngOnInit() {
    this.defaultGeography = localStorage.getItem("defaultGeography");
  }

  toggleFinYear(year) {
    if (year.id !== "All") {
      this.finYearChange.emit(year);
    } else if (year.id === "All") {
      const finYear = this.financialYearList;
      finYear.shift();
      this.finYearChange.emit(finYear);
    }
  }

  generateTable(data) {  
    let hiddenRows = [];
    if (this.selectProductLength) {
      if (this.selectProductLength.length > 1) {
        hiddenRows = [0];
      }
    }

    if (data && !this.isCollapsed) {
      const currentMonth = getCurrentMonth(this.selectedFinancialYear);
      let me = this;

      this.hotSettings = {
        data: data.data,
        colHeaders: true,
        licenseKey: "non-commercial-and-evaluation",
        stretchH: "all",
        preventOverflow: "horizontal",
        className: "htCenter",
        formulas: true,
        allowHtml: true,
        fixedColumnsLeft: 4,
        viewportRowRenderingOffset: 180,
        afterSelection: function (
          r,
          c,
          r2,
          c2,
          preventScrolling,
          selectionLayerLevel
        ) {
          preventScrolling.value = true;
        },
        rowHeights: function (row) {
          return "auto";
        },
        columnSummary: data.columnSummary,
        nestedHeaders: [data.nestedHeaders],
        hiddenRows: {
          copyPasteEnabled: false,
          rows: hiddenRows,
        } as any,
        mergeCells: this.mergeCellsForFy(data),
        fillHandle: false,
        beforePaste: function (data1, coords) {
          return false;
        },
        afterGetColHeader: function (col, TH) {
          function applyClass(elem, className) {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }
          if (TH.children[0].children[0].innerHTML === currentMonth) {
            applyClass(TH, "color2");
          } else {
            applyClass(TH, "color1");
          }
        },
        cells: (row, col) => {
          const hot = me.hotRegisterer.getInstance(me.hotID);
          let cp: any = {};
          if (row !== 1) {
            const cellData = hot.getDataAtCell(row, col);
            if (cellData < 0) {
              hot.setCellMeta(row, col, "valid", false);
            } else {
              hot.setCellMeta(row, col, "valid", true);
            }
          }
          if (col < 4 || row === 1 || col === 15) {
            cp.readOnly = true;
          }
          if (col > 3) {
            cp.type = "numeric";
            cp.allowEmpty = false;
            cp.className = "htCenter volume-cells";
          }
          if (col > 3 && row === 0) {
            cp.className = "bold-td htCenter volume-cells";
          }
          if (row === 1) {
            cp.className = "color3";
          }
          if (col < 4 && row > 1) {
            cp.className = "color5 firstChild";
            cp.type = "numeric";
          }
          if (col < 4 && row === 0) {
            cp.className = "color5 firstChild";
          }
          if (col > 3 && col <= 16) {
            cp.numericFormat = { pattern: "0,00.0" };
          }
          cp.readOnly = true;
          return cp;
        },
      };
      if (this.predictiveHot) {
        this.predictiveHot.updateHotTable(this.hotSettings);
      }
    }
  }

  mergeCellsForFy(data: any) {
    const dataValue = data.data;
    const mergingAmount = this.financialYearList.length - 1;
    const mergeCellInfo = [{ row: 0, col: 0, rowspan: 1, colspan: 4 }];
    let i = 0;
    while (i < dataValue.length - 1) {
      if (mergingAmount === 2) {
        let val1 = null;
        let val2 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          if (val1 && val2) {
            if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = null;
        }
      } else if (mergingAmount === 3) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3) {
            if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 !== val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
          }
        }
      } else if (mergingAmount === 4) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        let val4 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          val4 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3 && val4) {
            if (val1 === val2 && val2 === val3 && val3 === val4) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            } else if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          val4 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
          }
        }
      }
      i = i + 1;
    }
    return mergeCellInfo;
  }

  getGeoCategory() {
    if (this.userLevel) {
      return this.userLevel;
    } else {
      if (this.profile) {
        return this.profile.geoCategory;
      }
    }
  }

  collapseTableView(collapse: NgbCollapse) {
    collapse.collapsed = !collapse.collapsed;
    this.isCollapsed = collapse.collapsed;
    if (!collapse.collapsed) {
      if (this.tenant === MEXICO) {
        if (!this.tableData) {
          let params = JSON.parse(JSON.stringify(this.selectedFilter));
          let geoId = "";
          Object.assign(params, {
            tableIdentifier: this.data,
          });
          if (this.adminGeoId) {
            geoId = this.adminGeoId;
          } else {
            geoId = this.defaultGeography;
          }
          if (!this.userLevel) {
            this.dashBoardService
              .getForecastingTableOnDemand(params, geoId)
              .subscribe((res) => {
                this.generatePredictiveTable(res);
              });
          } else {
            this.dashBoardService
              .getAdminForecastingTableOnDemand(params, geoId, this.levelId)
              .subscribe((res) => {
                this.generatePredictiveTable(res);
              });
          }
        } else {
          setTimeout(() => {
            this.generateTable(this.data);
          }, 0);
        }
      } else {
        setTimeout(() => {
          this.generateTable(this.data);
        }, 0);
      }
    }
  }

  generatePredictiveTable(tabledata) {
    this.tableData = tabledata;
    setTimeout(() => {
      this.generateTable(this.tableData);
    }, 0);
  }
}
