import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  ViewEncapsulation,
} from "@angular/core";
import { HotTableComponent, HotTableRegisterer } from "@handsontable/angular";
import Handsontable from "handsontable";
import { getCurrentMonth } from "src/app/core/helpers/getCurrentMonth.helper";
import { ToastrMessageService } from "src/app/core/services/toast-message.service";
import { MONTHS } from "src/app/core/constants/month.constants";
import { NgbCollapse } from "@ng-bootstrap/ng-bootstrap";
import { DashboardApiService } from "src/app/core/services/dashboard-api.service";
import { TenantService } from "src/app/core/services/tenant.service";
import { Languages } from "src/app/core/constants/languages.constants";
import { MEXICO } from "src/app/core/constants/tenant.constants";
import {
  PARTICULARS,
  PARTICULARS1,
  PARTICULARS2,
  TOTAL,
} from "src/app/core/constants/string.constants";

@Component({
  selector: "app-common-y-one-table",
  templateUrl: "./common-y-one-table.component.html",
  styleUrls: ["./common-y-one-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CommonYOneTableComponent implements OnInit, OnChanges {
  @ViewChild("skuSplitHot", { static: false }) skuSplitHot: HotTableComponent;

  @Input() data;
  @Input() selectedFinancialYear;
  @Input() profile;
  @Input() isDataEdited;
  @Input() skuPriceMaster;
  @Input() canEditTable;
  @Input() level;
  @Input() adminGeoId;
  @Input() selectedFilter;
  @Input() userLevel;
  @Input() levelId;
  @Input() selectProductLength;
  @Input() financialYearList;

  @Output() dataEdited = new EventEmitter();
  @Output() updateRevenue = new EventEmitter();
  @Output() finYearChange = new EventEmitter();

  languages = Languages;
  hotSettings: Handsontable.GridSettings = null;
  hotData = null;

  hotID = "levelForecastHot";

  monthsList: any[] = MONTHS;
  isCollapsed: boolean = false;
  tableData: any;
  defaultGeography: any;

  constructor(
    private hotRegisterer: HotTableRegisterer,
    private toastMessageService: ToastrMessageService,
    private dashBoardService: DashboardApiService,
    private tenantService: TenantService
  ) {}

  ngOnChanges(changes: SimpleChanges) { 
    if (changes && changes.level && changes.level.currentValue) {
      this.hotID = changes.level.currentValue;
    }
    if (changes && changes.data && changes.data.currentValue) {
      this.hotSettings = null;
      this.isCollapsed = true;
    }
  }

  ngOnInit() {
    this.defaultGeography = localStorage.getItem("defaultGeography");
  }

  toggleFinYear(year) {
    if (year.id !== "All") {
      this.finYearChange.emit(year);
    } else if (year.id === "All") {
      const finYear = this.financialYearList;
      finYear.shift();
      this.finYearChange.emit(finYear);
    }
  }

  changeYearForSingleYear(data) {
    const tableData = data;
    const re = new RegExp("^([0-9]|-)+$");

    tableData.data.forEach((element, index) => {
      if (re.test(element[this.languages.LABEL_PARTICULARS3])) {
        let fYear: any = "20".concat(
          element[this.languages.LABEL_PARTICULARS3]
        );
        const rangeIni = fYear.split("-")[0] - 2000 - 1;
        const rangeLast = fYear.split("-")[1] - 1;
        const fY = rangeIni + "-" + rangeLast;
        tableData.data[index][this.languages.LABEL_PARTICULARS3] = fY;
      }
    });
    return tableData;
  }

  generateTable(data) {
    this.changeYearForSingleYear(data);
    let hiddenRows = [];
    if (this.selectProductLength && this.selectProductLength.length > 1) {
      hiddenRows = [0];
    }
    let formulas;
    if (
      this.tenantService.getTenant() !== MEXICO ||
      (this.selectProductLength && this.selectProductLength.length < 2)
    ) {
      formulas = true;
    } else {
      formulas = false;
    }
    if (data && !this.isCollapsed) {
      const editableMonths = this.getEditableMonth();
      const me = this;
      const currentMonth = getCurrentMonth(this.selectedFinancialYear);
      let currentColumn = null;
      let editableCols = [];

      this.hotSettings = {
        data: data.data,
        colHeaders: true,
        licenseKey: "non-commercial-and-evaluation",
        stretchH: "all",
        preventOverflow: "horizontal",
        className: "htCenter",
        formulas: formulas,
        allowHtml: true,
        fixedColumnsLeft: 4,
        viewportRowRenderingOffset: 180,
        afterSelection: function (
          r,
          c,
          r2,
          c2,
          preventScrolling,
          selectionLayerLevel
        ) {
          preventScrolling.value = true;
        },
        rowHeights: function (row) {
          return "auto";
        },
        columnSummary: data.columnSummary,
        hiddenRows: {
          copyPasteEnabled: false,
          rows: hiddenRows,
        } as any,
        nestedHeaders: [data.nestedHeaders],
        mergeCells: this.mergeCellsForFy(data),
        fillHandle: false,
        beforeCreateRow: function (index, amount, source) {
          return false;
        },
        afterGetColHeader: function (col, TH) {
          function applyClass(elem, className) {
            if (!Handsontable.dom.hasClass(elem, className)) {
              Handsontable.dom.addClass(elem, className);
            }
          }
          if (currentMonth) {
            if (editableMonths.includes(TH.children[0].children[0].innerHTML)) {
              if (!editableCols.includes(col)) {
                editableCols.push(col);
              }
            }
            if (TH.children[0].children[0].innerHTML === currentMonth) {
              currentColumn = col;
              applyClass(TH, "color2");
            } else {
              applyClass(TH, "color1");
            }
          } else {
            if (
              TH.children[0].children[0].innerHTML === me.monthsList[3].month
            ) {
              currentColumn = col;
              applyClass(TH, "color1");
            } else {
              applyClass(TH, "color1");
            }
          }
        },
        cells: (row, col) => {
          const hotInstance = me.hotRegisterer.getInstance(me.hotID);
          let cp: any = {};
          if (row !== 1) {
            const hotInstanceData = hotInstance.getDataAtCell(row, col);
            if (hotInstanceData < 0) {
              hotInstance.setCellMeta(row, col, "valid", false);
            } else {
              hotInstance.setCellMeta(row, col, "valid", true);
            }
          }
          if (col < 4 || row === 1 || col === 16) {
            cp.readOnly = true;
            cp.type = "numeric";
          }
          if (col > 3) {
            cp.allowEmpty = false;
            cp.className = "htCenter volume-cells";
            cp.type = "numeric";
            if (row === 0) {
              cp.className = "bold-td htCenter volume-cells";
              cp.type = "numeric";
            }
          }
          if (row === 1) {
            cp.className = "color3";
            cp.type = "numeric";
          }
          if (col < 4 && row > 1) {
            cp.className = "color5 volume-cells firstChild";
            cp.type = "numeric";
          }
          if (col < 4 && row === 0) {
            cp.className = "color5 firstChild";
          }
          if (col >= currentColumn && row !== 1) {
            cp.className = "htCenter";
          }
          if (currentMonth) {
            if (col >= currentColumn && row === 0) {
              cp.className = "htCenter total-cell";
              if (col !== hotInstance.countCols() - 1) {
                cp.readOnly = false;
              }
            }
          } else {
            if (col >= currentColumn && row === 0) {
              cp.className = "htCenter total-cell volume-cells";
              cp.readOnly = true;
            }
          }
          if (col < currentColumn) {
            cp.readOnly = true;
          }
          if (col === 16) {
            cp.numericFormat = { pattern: "0,00.0" };
          }
          if (col > 3 && col < 16) {
            cp.numericFormat = { pattern: "0,00.0" };
          }
          if (currentMonth) {
            if (!me.canEditTable && col >= currentColumn && row !== 1) {
              cp.readOnly = true;

              if (row === 0) {
                cp.className =
                  col !== 16
                    ? "htCenter volume-cells total-cell noneditablehtInvalid"
                    : "htCenter volume-cells total-cell";
              } else {
                cp.className =
                  col !== 16
                    ? "htCenter volume-cells noneditablehtInvalid"
                    : "htCenter volume-cells";
              }
            } else if (
              me.canEditTable &&
              editableCols.includes(col) &&
              row !== 1 &&
              col >= currentColumn
            ) {
              cp.readOnly = false;
              //editable col backgroudcolor white
              cp.className =
                row !== 0
                  ? "htCenter editablehtInvalid htInvalid"
                  : "htCenter editablehtInvalid htInvalid total-cell";
            } else if (
              me.canEditTable &&
              !editableCols.includes(col) &&
              row !== 1 &&
              col >= currentColumn
            ) {
              cp.readOnly = true;
              cp.className =
                row === 0
                  ? "htCenter volume-cells total-cell"
                  : "htCenter volume-cells";
            } else if (
              (!me.canEditTable &&
                row !== 1 &&
                col > 3 &&
                col < currentColumn) ||
              (me.canEditTable &&
                editableCols.includes(col) &&
                row !== 1 &&
                col > 3 &&
                col < currentColumn)
            ) {
              cp.readOnly = true;
              cp.className =
                row !== 0
                  ? "htCenter noneditablehtInvalid htInvalid"
                  : "htCenter noneditablehtInvalid htInvalid total-cell";
            }
          } else {
            if (col >= currentColumn && row !== 1) {
              cp.readOnly = true;
              cp.className =
                row === 0
                  ? "htCenter volume-cells total-cell"
                  : "htCenter volume-cells";
            }
          }
          cp.forceNumeric = true;
          return cp;
        },
        afterChange: (changes, src) => {
          if (this.selectProductLength && this.selectProductLength.length > 1) {
            this.calculateTotal();
          }
        },
      };
      const hot = me.hotRegisterer.getInstance(me.hotID);
      if (hot) {
        hot.render();
      }
    }
  }

  mergeCellsForFy(data: any) {
    const dataValue = data.data;
    const mergingAmount = this.financialYearList.length - 1;
    const mergeCellInfo = [{ row: 0, col: 0, rowspan: 1, colspan: 4 }];
    let i = 0;
    while (i < dataValue.length - 1) {
      if (mergingAmount === 2) {
        let val1 = null;
        let val2 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          if (val1 && val2) {
            if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = null;
        }
      } else if (mergingAmount === 3) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3) {
            if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 !== val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 1,
              colspan: 1,
            });
          }
        }
      } else if (mergingAmount === 4) {
        let val1 = null;
        let val2 = null;
        let val3 = null;
        let val4 = null;
        try {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          val4 = dataValue[i + 2][this.languages.LABEL_PARTICULARS];
          if (val1 && val2 && val3 && val4) {
            if (val1 === val2 && val2 === val3 && val3 === val4) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount,
                colspan: 1,
              });
            } else if (val1 === val2 && val2 === val3) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 1,
                colspan: 1,
              });
            } else if (val1 === val2) {
              mergeCellInfo.push({
                row: i,
                col: 0,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 1,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
              mergeCellInfo.push({
                row: i,
                col: 2,
                rowspan: mergingAmount - 2,
                colspan: 1,
              });
            }
          }
        } catch (error) {
          val1 = dataValue[i][this.languages.LABEL_PARTICULARS];
          val2 = dataValue[i + 1][this.languages.LABEL_PARTICULARS];
          val3 = null;
          val4 = null;
          if (val1 === val2) {
            mergeCellInfo.push({
              row: i,
              col: 0,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 1,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
            mergeCellInfo.push({
              row: i,
              col: 2,
              rowspan: mergingAmount - 2,
              colspan: 1,
            });
          }
        }
      }
      i = i + 1;
    }
    return mergeCellInfo;
  }

  getEditableMonth() {
    let dt = new Date();
    const futureDate = dt.setMonth(dt.getMonth() + 18);
    let data = [];
    let editableMonths = [];
    const yearValue = this.selectedFinancialYear.name.split("-");
    for (let i = 1; i <= 12; i++) {
      if (i <= 3) {
        data.push("20" + yearValue[1] + "/" + this.monthsList[i - 1].id);
      } else {
        data.push(yearValue[0] + "/" + this.monthsList[i - 1].id);
      }
    }
    data.forEach((ele) => {
      if (
        new Date(ele).setHours(0, 0, 0, 0) <
        new Date(futureDate).setHours(0, 0, 0, 0)
      ) {
        editableMonths.push(this.monthsList[ele.split("/")[1] - 1].month);
      }
    });
    return editableMonths;
  }

  calculateTotal() {
    let hotDataInstance = this.hotRegisterer.getInstance(this.hotID);
    let index = this.selectProductLength.length < 2 ? 0 : 2;
    for (let i = index; i < hotDataInstance.countRows(); i++) {
      if (i !== 1) {
        let total = null;
        for (const [key, value] of Object.entries(this.tableData.data[i])) {
          if (
            key !== this.languages.LABEL_PARTICULARS &&
            key !== this.languages.LABEL_PARTICULARS3 &&
            key !== this.languages.LABEL_PARTICULARS1 &&
            key !== this.languages.LABEL_PARTICULARS2 &&
            key !== this.languages.LABEL_TOTAL
          ) {
            total = total + Number(value);
          }
        }
        this.tableData.data[i][this.languages.LABEL_TOTAL] = total;
      }
    }
    hotDataInstance.render();
  }

  changeRevenue(total, column) {
    const params = {
      total: total,
      column: column,
    };
    this.updateRevenue.emit(params);
  }

  getLevel() {
    if (this.level) {
      return this.level;
    } else {
      return this.profile.level
        .match(/\b(\w)/g)
        .join("")
        .toUpperCase();
    }
  }

  isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  collapseTableView(collapse: NgbCollapse) {
    collapse.collapsed = !collapse.collapsed;
    this.isCollapsed = collapse.collapsed;
    if (!collapse.collapsed) {
      if (!this.tableData) {
        let params = JSON.parse(JSON.stringify(this.selectedFilter));
        let geoId = "";
        Object.assign(params, {
          tableIdentifier: this.data,
        });
        if (this.adminGeoId) {
          geoId = this.adminGeoId;
        } else {
          geoId = this.defaultGeography;
        }
        if (!this.userLevel) {
          this.dashBoardService
            .getForecastingTableOnDemand(params, geoId)
            .subscribe((res) => {
              this.generateMexicoSplitTable(res);
            });
        } else {
          this.dashBoardService
            .getAdminForecastingTableOnDemand(params, geoId, this.levelId)
            .subscribe((res) => {
              this.generateMexicoSplitTable(res);
            });
        }
      } else {
        setTimeout(() => {
          this.generateTable(this.data);
        }, 0);
      }
    }
  }

  generateMexicoSplitTable(data) {
    this.tableData = data;
    setTimeout(() => {
      this.generateTable(this.tableData);
    }, 0);
  }
}
