import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class TenantService {

  config = localStorage.getItem('config');

  constructor() { //constructor
   }

  getTenantForHostname(hostname: string): Tenant {
    return this.getTenantForString(hostname);
  }

  getTenantForString(s: string) {
    for (const e in Tenant) {
      if (e.toLowerCase() === s.toLowerCase()) {
        return Tenant[e] as Tenant;
      }
    }
    return null;
  }

  getTenant(): Tenant {
    const tenant = localStorage.getItem('defaultTenant');
    if (tenant) {
      return this.getTenantForHostname(tenant);
    } else {
      return null;
    }
  }

}

export enum Tenant {
  DO1301 = "AF",
  DO1401 = "SWAL",
  LA0005 = "MEXICO"
}
