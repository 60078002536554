import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Languages } from 'src/app/core/constants/languages.constants';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  @Output() response = new EventEmitter();
  @Input() description;
  languages=Languages;

  constructor(public modal: NgbActiveModal) { }

  ngOnInit() {
    //ngOninit 
  }

  submit(value) {
    this.response.emit(value);
    this.modal.close();
  }

  onOk() {
    this.modal.close();
  }

}
