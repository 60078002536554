import { MONTHS } from '../constants/month.constants';

export const getCurrentMonth = (selectedFinancialYear) => {  
    const monthsList: any[] = MONTHS;
    const monthIndex = new Date().getMonth() + 1;
    if(selectedFinancialYear.name === 'All'){
        const dte = new Date();
        const currentYr = dte.getFullYear();
        const currentYr1 = dte.getFullYear()+1;
        const toText = currentYr1.toString(); 
        const last2Char = toText.slice(-2); 
        const selectFinYr = currentYr+'-'+last2Char;
        const yearValue = selectFinYr.split('-'); 
        const selectedYear = monthIndex <= 3 ? Number("20" + yearValue[1]) : Number(yearValue[0]); // 4, 2021
        const currentYear = new Date().getFullYear();
    
        let currentMonth = '';
        if (currentYear - selectedYear >= 1 || (currentYear - selectedYear === 0 && monthIndex === 3)) {
                        currentMonth = null;
        } else if (currentYear - selectedYear === 0 && (monthIndex !=3)) {
            monthsList.forEach((month, index) => {
                if (monthIndex === month.id) {
                    if (index + 1 === monthsList.length) {
                        currentMonth = monthsList[0].month;
                    } else if (index + 1 < monthsList.length) {
                        currentMonth = monthsList[index + 1].month;
                    } else {
                        currentMonth = null;
                    }
                }
            });
        }
       else {
            currentMonth = monthsList[3].month;
        } 
        
console.log("if",currentMonth)
        return currentMonth;
       
    }
    else{
            const yearValue = selectedFinancialYear.name.split('-'); 
            const selectedYear = monthIndex <= 3 ? Number("20" + yearValue[1]) : Number(yearValue[0]); // 4, 2021
            const currentYear = new Date().getFullYear();
        
            let currentMonth = '';
            if (currentYear - selectedYear >= 1 || (currentYear - selectedYear === 0 && monthIndex === 3)) {
                            currentMonth = null;
            } else if (currentYear - selectedYear === 0 && (monthIndex !=3)) {
                monthsList.forEach((month, index) => {
                    if (monthIndex === month.id) {
                        if (index + 1 === monthsList.length) {
                            currentMonth = monthsList[0].month;
                        } else if (index + 1 < monthsList.length) {
                            currentMonth = monthsList[index + 1].month;
                        } else {
                            currentMonth = null;
                        }
                    }
                });
            }
           else {
                currentMonth = monthsList[3].month;
            } 
            console.log("else",currentMonth)
            return currentMonth;
    } 

}