import { Component, OnDestroy, OnInit } from "@angular/core";
import { LoaderComponent } from "./shared/loader/loader.component";
import { fromEvent, Observable, Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { DashboardApiService } from "./core/services/dashboard-api.service";
import { AuthService } from "./core/services/auth.service";
import { Router } from "@angular/router";
import "core-js/stable";
import "regenerator-runtime/runtime";
import { Languages } from "./core/constants/languages.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  title = "uplsalesportal";

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  languages = Languages;
  restrictedUrlArray = [];
  public loaderComponent = LoaderComponent;

  constructor(
    private toastrService: ToastrService,
    private dashboardService: DashboardApiService,
    private authService: AuthService,
    private router: Router
  ) {
    //restrict loader on specific api calls
    this.restrictedUrlArray = ["assets/images/icons8-cancel-50.png"];
    if (localStorage.getItem("token")) {
      this.dashboardService.getProfitCenterConfigCheck().subscribe((res) => {
        if (res.config.version) {
          const version = JSON.parse(localStorage.getItem("config"));
          if (
            version.config &&
            version.config.version &&
            version.config.version !== res.config.version
          ) {
            this.authService.errorLogout();
            this.router.navigate([""]);
          }
        }
      });
    }
  }

  ngOnInit() {
    this.onlineEvent = fromEvent(window, "online");
    this.offlineEvent = fromEvent(window, "offline");
    this.subscriptions.push(
      this.onlineEvent.subscribe((event) => {
        this.toastrService.success(
          "Connected to internet! You are online",
          "Success"
        );
        window.location.reload();
      })
    );
    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this.toastrService.error("Connection lost! You are offline", "Error");
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
