import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TenantService } from '../services/tenant.service';
import { ToastrMessageService } from '../services/toast-message.service';

@Injectable({
  providedIn: 'root'
})
export class ReverseAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastMessageService: ToastrMessageService,
    private tenantService: TenantService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem('token');
    const profile = JSON.parse(localStorage.getItem('profile'));
    const defaultGeography = JSON.parse(localStorage.getItem('defaultGeography'));
    const assignedLevel = localStorage.getItem('assignedLevel');
    const defaultTenant = localStorage.getItem('defaultTenant');
    if (token) {
      const tenant = this.tenantService.getTenant();
      this.toastMessageService.successMessage('Already Logged In!');
      if (!defaultTenant) {
        this.router.navigate(['/profit-center']);
      } else if (profile.assignedLevels.length > 1 && !assignedLevel) {
        this.router.navigate(['/level']);
      } else if (profile.hasGeography && !defaultGeography) {
        this.router.navigate(['/geography']);
      } else {
        this.router.navigate(['/' + tenant.toLowerCase() + '/forecasting']);
      }
      return false;
    } else {
      return true;
    }
  }

}
