import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, SimpleChanges,ViewEncapsulation } from '@angular/core';
import { NgSelectConfig, NgSelectComponent } from '@ng-select/ng-select';
import { FormGroup} from '@angular/forms';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Languages } from 'src/app/core/constants/languages.constants';
import { AF, MEXICO, SWAL } from 'src/app/core/constants/tenant.constants';


@Component({
  selector: 'app-multi-select-wo-label',
  templateUrl: './multi-select-wo-label.component.html',
  styleUrls: ['./multi-select-wo-label.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiSelectWoLabelComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() bindLabel;
  @Input() bindValue;
  @Input() placeholder;
  @Input() clear;
  @Input() virtualScroll;

  @Input() form: FormGroup;
  @Input() control;

  @Output() change = new EventEmitter();

  showFilters = false;
  checkedField = false;
  currentTenant;
  

  searchedFilters = [];
  canViewSelectAll = true;

  virtualScrollValue = true;
  languages=Languages;

  MEXICO=MEXICO;
  SWAL=SWAL;
  AF=AF;

  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;

  constructor(private config: NgSelectConfig,
    private tenantService: TenantService) {
    config.notFoundText = 'No Data Available';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && changes.data.currentValue) {
      this.searchedFilters = changes.data.currentValue;
      this.canViewSelectAll = this.checkFilterLength();
    }
    if (changes && changes.virtualScroll && changes.virtualScroll.currentValue) {
      this.virtualScrollValue = false;
    }
  }

  ngOnInit() {
    this.currentTenant = this.tenantService.getTenant();
  }

  getItem(item) {
    return item[this.bindLabel];
  }

  onSelectAll(event) {
    this.checkedField = event.target.checked;
    if (event.target.checked) {
      const selected = this.searchedFilters.map(item => item[this.bindValue]);
      this.form.get(this.control).patchValue(selected);
    } else {
      this.form.get(this.control).patchValue([]);
    }
    this.ngSelectComponent.filter(this.ngSelectComponent.searchTerm);
  }

  onFocus(event) {
    if (this.form.get(this.control).value) {
      if (this.form.get(this.control).value.length === this.data.length) {
        this.checkedField = true;
      } else {
        this.checkedField = false;
      }
    } else {
      this.checkedField = false;
    }
    this.ngSelectComponent.filter(this.ngSelectComponent.searchTerm);
  }

  onChange(event) {
    this.ngSelectComponent.filter(this.ngSelectComponent.searchTerm);
  }

  onClear($event) {
    this.ngSelectComponent.filter(this.ngSelectComponent.searchTerm);
  }

  checkFilterLength() {
    if (this.searchedFilters.length > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  onSearch($event) {

    this.searchedFilters = $event.items;
    if (this.form.get(this.control) && this.form.get(this.control).value) {
      if (this.form.get(this.control).value.length === this.searchedFilters.length) {
        this.checkedField = true;
      } else {
        this.checkedField = false;
      }
    }
    this.canViewSelectAll = this.checkFilterLength();
  }

}
